import { useParams } from 'react-router-dom';
import papers from '../context/papers.json';
import styles from "../styles/Paper.module.css";

function Paper() {
    const { paperId } = useParams();
    const paper = papers.find((p, i) => i === Number(paperId));

    if (!paper) {
        return <p>No paper found!</p>; // or handle this case as you see fit
    }

    function Question({ data }) {
        console.log(data);

        return (
            <div className={styles.questionContainer}>
                {data.content.map((structure, i) => {
                    switch (structure.type) {
                        case "text":
                            return (
                                <h3>{structure.latex}</h3>
                            )
                        case "table":
                            return (
                                <h3>{structure.latex}</h3>
                            )
                    }
                })}
                {data?.questions?.length && data.questions.map((question, i) => <Question data={question} />)}
            </div>
        );
    };

    // Fetch the paper details using paperId or process it as required
    return (
        <div>
            <h1>Paper Details for {paper.info.subject}</h1>
            <div>
                {paper.questions.map((question, index) => (
                    <Question key={index} data={question} />
                ))}
            </div>
        </div>
    );
}

export default Paper;
