import { useParams } from 'react-router-dom';
import { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../context/DataContext';
import styles from "../styles/Memorise.module.css";
import { useNavigate } from 'react-router-dom';
import { CornerDownLeft } from 'lucide-react';
import ThemeToggle from '../components/ThemeToggle';

export default function Memorise() {
  const { topic, subtopic } = useParams();
  const paper = `${topic}-${subtopic}`;
  const { overview, theme } = useContext(DataContext);
  const [pool, setPool] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);
  const [score, setScore] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [streak, setStreak] = useState(0);
  const [highestStreak, setHighestStreak] = useState(0);
  const navigate = useNavigate();
  const [hasAttempted, setHasAttempted] = useState(false);
  const currentCardRef = useRef(null);

  useEffect(() => {
    if (overview && topic && subtopic && overview[topic] && overview[topic].topics[subtopic]) {
      const newPool = overview[topic].topics[subtopic].memorise.flashcards;
      setPool(newPool);
      const newCard = newPool[Math.floor(Math.random() * newPool.length)]
      setCurrentCard(newCard);
      currentCardRef.current = newCard;
    }
  }, [overview, topic, subtopic]);

  const newQuestion = () => {
    if (pool.length > 1) { // Ensure there is more than one card to avoid infinite loop
      let newPool = pool.filter(card => card !== currentCardRef.current); // Filter out the current card
      console.log(currentCard, newPool.length, pool.length);
      const newCard = newPool[Math.floor(Math.random() * newPool.length)];
      setCurrentCard(newCard);
      currentCardRef.current = newCard;
    }
    setIsCorrect(null);
    setUserInput('');
    setHasAttempted(false);
  };


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'Enter' && pool.length > 0) {
        event.preventDefault(); // Prevent any default form submission
        newQuestion();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [pool]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hasAttempted) return;

    setHasAttempted(true);
    setAttempts(prev => prev + 1);
    if (userInput.trim().toLowerCase() === currentCard.answer.toLowerCase()) {
      setIsCorrect(true);
      setScore(prev => prev + 1);
      setStreak(prev => prev + 1);
      setHighestStreak(prev => Math.max(prev, streak + 1));
    } else {
      setIsCorrect(false);
      setStreak(0);
    }
  };

  const handleReturn = () => {
    navigate("/alisakan");
  };

  const percentage = attempts > 0 ? (score / attempts * 100).toFixed(2) : 0;

  if (!paper || !currentCard) {
    return <p>Error occurred, sorry Alisa!</p>;
  }

  return (
    <div className={styles.container}>
      <div className={styles[theme === "dark" ? "noisyDark" : "noisyLight"]}></div>
      <div className={styles.navBar}>
        <button className={styles.returnButton} onClick={handleReturn}>
          <CornerDownLeft style={{ marginRight: "10px" }} />
          Return
        </button>
        <ThemeToggle />
      </div>
      <div className={styles.scoreboard}>
        <h3 className={styles.score}>Score: {score}/{attempts}</h3>
        <h3 className={styles.percentage}>Accuracy: {percentage}%</h3>
        <h3 className={styles.streak}>Streak: {streak} (Highest: {highestStreak})</h3>
      </div>
      <div className={`${styles.cardContainer} ${isCorrect ? styles.cardBorderCorrect : ''}`}>
        <div className={styles.cardBorder}></div>
        <div className={styles.questionContainer}>
          <h3 className={styles.questionTitle}>{currentCard.question}</h3>
        </div>
        <div className={`${styles.statusContainer} ${isCorrect ? styles.correctAnswer : styles.wrongAnswer}`}>
          {
            isCorrect !== null &&
            <>
              {isCorrect ? "Correct!" : `${currentCard.answer}`}
            </>
          }
        </div>
        <form onSubmit={handleSubmit} className={styles.answerContainer}>
          <div className={styles.inputContainer}>
            <input
              type="text"
              value={userInput}
              onChange={handleInputChange}
              placeholder="Type your answer here..."
              className={styles.inputField}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.ctrlKey) {
                  e.preventDefault(); // This ensures "Ctrl+Enter" does not submit the form
                }
              }}
            />
          </div>
          <div className={styles.functionContainer}>
            <button type="button" className={styles.skipButton} onClick={newQuestion}>Skip</button>
            <button type="submit" className={styles.submitButton}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
