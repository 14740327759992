import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBzeg_a3bJIcHaC436BH7i44qpH5_45q10",
    authDomain: "a-level-simulator.firebaseapp.com",
    projectId: "a-level-simulator",
    storageBucket: "a-level-simulator.appspot.com",
    messagingSenderId: "848083984476",
    appId: "1:848083984476:web:955edf800f5f26d8ea4699",
    measurementId: "G-PKNN7YM5XD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
export { auth, provider };