export default function Pearson2({ styling }) {
    return (
        <svg style={styling} version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="2802.000000pt" height="2213.000000pt" viewBox="0 0 2802.000000 2213.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,2213.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M13865 18054 c-829 -66 -1544 -333 -2179 -813 -755 -569 -1350 -1455
-1600 -2381 -130 -481 -192 -1084 -167 -1622 37 -804 207 -1509 515 -2134 365
-741 853 -1250 1511 -1574 344 -170 641 -262 1035 -322 193 -29 645 -36 857
-14 944 100 1831 527 2547 1226 388 378 681 779 941 1284 801 1552 911 3136
304 4349 -170 340 -344 581 -619 858 -303 305 -559 487 -972 694 -467 233
-962 378 -1483 435 -101 11 -598 21 -690 14z m450 -1574 c683 -41 1185 -217
1622 -568 127 -103 313 -297 395 -414 272 -384 380 -948 277 -1451 -87 -427
-338 -826 -705 -1117 -428 -340 -936 -509 -1660 -550 -98 -6 -180 -12 -182
-15 -3 -2 -9 -69 -13 -147 -15 -245 -26 -324 -53 -383 -66 -144 -263 -248
-496 -262 -81 -4 -103 -2 -151 16 -67 25 -124 78 -151 138 -26 60 -35 168 -48
573 -24 808 -50 1921 -50 2161 0 217 9 249 89 330 103 102 275 153 516 153
268 0 364 -47 409 -201 26 -90 29 -180 17 -524 -6 -184 -16 -467 -21 -629 -6
-162 -13 -373 -17 -468 l-6 -172 44 5 c202 21 260 31 375 60 154 39 321 103
489 187 482 241 734 558 817 1028 19 113 17 388 -5 478 -54 225 -160 408 -341
587 -274 274 -644 433 -1097 476 -164 15 -553 7 -754 -16 -554 -63 -923 -207
-1349 -523 -124 -93 -223 -176 -431 -362 -98 -88 -146 -110 -241 -110 -222 0
-301 208 -174 460 41 83 132 194 269 330 248 246 444 386 763 545 641 319
1160 426 1863 385z m-640 -5225 c84 -22 147 -60 209 -125 281 -295 55 -721
-352 -661 -230 34 -352 175 -340 395 5 104 44 192 120 272 62 66 113 98 188
118 72 19 103 19 175 1z"/>
                <path d="M4070 8103 c-26 -33 -21 -78 10 -105 11 -9 53 -21 92 -27 256 -39
321 -62 387 -137 83 -94 76 56 79 -1704 2 -1153 0 -1579 -8 -1620 -37 -174
-190 -270 -491 -305 -37 -4 -63 -13 -75 -26 -24 -26 -21 -79 7 -101 20 -17 77
-18 910 -18 l888 0 21 22 c11 12 20 34 20 50 0 44 -29 65 -101 72 -309 33
-459 110 -514 265 -13 37 -15 128 -15 632 l0 589 263 0 c396 0 636 21 872 77
594 140 960 471 1051 952 24 126 22 338 -6 470 -107 523 -515 815 -1281 917
-106 15 -263 18 -1105 21 l-982 4 -22 -28z m1855 -153 c285 -27 470 -101 610
-245 83 -85 127 -156 166 -267 51 -149 63 -248 63 -508 0 -250 -14 -384 -56
-519 -74 -241 -233 -395 -483 -469 -147 -44 -244 -54 -602 -59 l-343 -6 0
1042 0 1041 268 0 c147 0 316 -5 377 -10z"/>
                <path d="M8585 6984 c-258 -47 -482 -163 -679 -353 -257 -250 -413 -563 -461
-929 -53 -401 33 -792 243 -1112 69 -104 240 -277 340 -343 161 -107 347 -180
541 -212 125 -21 413 -16 524 9 281 64 513 205 697 421 93 110 190 254 190
282 0 31 -42 73 -73 73 -31 0 -43 -11 -152 -138 -150 -175 -307 -286 -480
-338 -82 -25 -104 -27 -250 -27 -133 0 -174 4 -240 22 -218 59 -394 186 -519
374 -137 206 -194 420 -203 765 l-6 212 961 0 960 0 7 73 c9 93 -10 286 -40
402 -134 528 -562 838 -1150 834 -71 0 -166 -7 -210 -15z m310 -159 c269 -48
416 -244 455 -607 11 -106 14 -297 4 -322 -6 -14 -72 -16 -645 -16 -618 0
-639 1 -639 19 0 46 44 274 71 365 17 56 50 142 74 191 146 302 372 425 680
370z"/>
                <path d="M16395 6984 c-234 -44 -420 -138 -561 -286 -166 -173 -243 -387 -231
-643 7 -137 21 -195 72 -303 29 -61 54 -94 123 -162 132 -129 298 -212 597
-300 438 -128 594 -195 714 -306 51 -47 80 -84 103 -132 32 -64 33 -70 33
-192 0 -122 -1 -127 -34 -193 -102 -210 -362 -319 -655 -277 -289 42 -468 217
-780 763 -67 116 -68 117 -105 117 -20 0 -47 -7 -59 -16 l-22 -15 0 -510 0
-509 53 0 52 0 99 99 98 98 87 -43 c316 -158 747 -199 1041 -99 142 48 227
101 336 210 83 83 108 116 146 190 91 178 125 337 115 544 -10 210 -58 337
-177 464 -153 164 -297 233 -850 407 -348 110 -530 231 -575 382 -31 104 -12
239 48 340 87 149 292 241 498 224 265 -22 407 -145 686 -593 129 -209 118
-196 166 -190 76 10 72 -16 75 492 l3 455 -55 0 c-54 0 -55 -1 -105 -57 -27
-32 -58 -69 -68 -82 l-19 -23 -50 24 c-75 38 -179 75 -289 104 -128 34 -381
43 -510 18z"/>
                <path d="M19205 6994 c-319 -47 -576 -170 -798 -383 -139 -133 -227 -252 -310
-417 -292 -581 -183 -1323 259 -1768 236 -239 526 -373 877 -407 405 -39 819
118 1122 426 203 206 329 444 397 747 20 91 23 129 23 328 0 199 -3 237 -23
329 -87 385 -304 711 -610 915 -156 104 -316 171 -507 211 -77 17 -362 29
-430 19z m371 -188 c220 -71 372 -239 464 -515 66 -195 90 -371 97 -697 18
-859 -153 -1290 -557 -1400 -90 -24 -300 -25 -390 -1 -353 95 -543 404 -600
976 -15 152 -12 582 5 742 58 549 236 842 555 914 105 24 323 14 426 -19z"/>
                <path d="M14745 6975 c-224 -60 -416 -212 -577 -458 l-53 -80 -5 246 c-5 231
-6 247 -25 262 -18 14 -55 15 -315 10 -425 -8 -509 -14 -531 -36 -11 -11 -19
-30 -19 -43 0 -33 37 -76 65 -76 53 0 153 -40 198 -81 82 -72 78 7 75 -1276
-3 -1123 -3 -1132 -24 -1160 -31 -43 -98 -71 -192 -82 -93 -10 -122 -27 -122
-72 0 -18 9 -35 26 -48 26 -21 35 -21 631 -21 408 0 611 3 624 11 27 14 38 48
27 81 -11 31 -55 48 -128 48 -58 0 -164 25 -201 47 -16 10 -38 36 -49 58 -19
40 -20 65 -20 946 l0 905 79 119 c167 254 297 368 465 411 24 6 24 5 14 -38
-27 -118 17 -273 99 -349 140 -129 377 -125 505 8 139 144 124 421 -31 566
-92 87 -186 117 -356 116 -60 0 -132 -6 -160 -14z"/>
                <path d="M11330 6973 c-397 -42 -737 -227 -847 -459 -99 -209 -36 -434 146
-518 35 -16 65 -21 131 -21 76 0 92 4 145 32 71 37 157 124 188 190 17 37 22
67 22 129 0 73 -4 88 -37 155 -20 41 -54 94 -74 118 l-38 44 39 34 c48 42 158
96 240 118 79 21 303 31 375 16 202 -42 312 -177 354 -436 13 -79 16 -156 14
-369 l-3 -270 -60 -18 c-33 -10 -197 -52 -365 -94 -374 -92 -592 -164 -775
-253 -228 -112 -349 -236 -412 -423 -25 -77 -27 -95 -27 -243 0 -150 1 -164
27 -231 42 -112 75 -163 151 -239 120 -120 277 -186 483 -203 342 -29 625 87
915 373 l97 96 6 -48 c20 -151 60 -246 129 -311 77 -73 140 -94 301 -99 148
-6 245 12 380 68 107 45 140 96 98 148 -20 24 -29 26 -129 15 -122 -12 -179
27 -221 151 -16 45 -18 140 -23 960 -6 899 -6 911 -28 995 -38 144 -93 242
-192 341 -73 73 -100 93 -190 138 -114 56 -241 92 -385 110 -83 10 -354 13
-435 4z m660 -1855 l0 -421 -41 -53 c-50 -66 -169 -180 -233 -223 -125 -86
-252 -119 -389 -101 -248 33 -376 197 -377 480 0 170 40 270 150 373 106 101
248 175 480 252 117 39 375 113 398 114 9 1 12 -90 12 -421z"/>
                <path d="M22606 6964 c-234 -43 -422 -135 -576 -280 -76 -72 -138 -148 -183
-224 l-27 -45 0 195 c-1 227 -10 327 -31 341 -15 11 -685 4 -787 -8 -64 -7
-82 -23 -82 -72 0 -49 14 -60 97 -78 144 -30 217 -98 234 -220 6 -43 9 -486 7
-1154 -3 -1039 -4 -1086 -22 -1122 -25 -50 -76 -75 -179 -88 -110 -14 -131
-25 -135 -71 -6 -71 -36 -68 588 -68 619 0 590 -3 590 64 0 44 -16 56 -100 72
-86 16 -117 31 -144 67 -20 27 -21 46 -27 295 -4 147 -7 537 -8 867 l-1 600
41 75 c98 180 262 371 396 460 101 67 182 92 303 91 272 -1 411 -118 473 -398
22 -97 22 -109 22 -1023 0 -904 0 -926 -20 -958 -25 -41 -83 -69 -163 -78 -73
-8 -108 -38 -97 -84 13 -52 -12 -50 596 -50 l568 0 15 22 c41 59 2 102 -101
111 -132 13 -193 46 -213 115 -6 24 -10 364 -10 967 0 540 -5 961 -10 1005
-15 116 -47 226 -93 320 -59 120 -169 231 -287 288 -131 64 -180 74 -380 78
-125 2 -198 -1 -254 -12z"/>
            </g>
        </svg>
    )
}