import Moon from "../svgs/Moon";
import Sun from "../svgs/Sun";
import { useContext, useEffect } from "react";
import DataContext from "../context/DataContext";
import styles from "./ThemeToggle.module.css";

const darkThemeColors = {
    primaryColorOpacityHalf: "rgba(16, 16, 16, 0.5)",
    primaryColorOpacityQuarter: "rgba(16, 16, 16, 0.25)",
    primaryColor: "rgba(16, 16, 16,1)",
    newPrimaryColor: "rgba(10,10,10,1)",
    secondaryColor: "rgba(240,240,255,1)",
    secondaryColorDarker: "rgba(230,230,245,1)",
    // secondaryColor: "rgba(255,255,255,1)",
    tertiaryColor: "rgba(25, 25, 25,1)",
    quaternaryColor: "rgba(40, 40, 40, 1)",
    quinaryColor: "rgba(10, 10, 10, 1)",
    testBackgroundColor: "#111",
    grey1: "#333",
    boxShadow1: "-1px -4px 70px -15px rgba(0, 0, 0, 1)",
    boxShadowButton: "-1px -4px 70px -15px rgba(10, 10, 50, 1)",
    underLine: "rgba(255, 255, 255, 0.08)",
    primaryColorLighter: "rgba(20, 20, 20, 1)",
    primaryColorLighter2: "rgba(20, 20, 20,0.75)",
    secondaryColorLighter: "rgba(255,255,255,0.25)",
    secondaryColorLighter2: "rgba(255,255,255,0.75)",
    pageContrast: "rgba(0, 0, 16, 1)",
    homeColor: "rgba(8,4,20,1)",
    buttonBgColor: "rgba(3, 0, 10, 0.5)",
    buttonColor: "#fff",
}

const lightThemeColors = {
    primaryColorOpacityHalf: "rgba(255, 255, 255, 0.5)",
    primaryColorOpacityQuarter: "rgba(255, 255, 255, 0.25)",
    primaryColor: "#fff",
    newPrimaryColor: "#fff",
    secondaryColor: "#000",
    secondaryColorDarker: "rgba(60,60,60,1)",
    tertiaryColor: "rgba(200,200,200,1)",
    quaternaryColor: "rgba(200, 200, 200, 1)",
    quinaryColor: "rgba(230, 230, 240, 1)",
    testBackgroundColor: "#aaa",
    grey1: "#999",
    boxShadow1: "0px 0px 30px 10px rgba(240, 240, 245, 1)",
    boxShadowButton: "0px 0px 30px 10px rgba(240, 240, 255, 1)",
    underLine: "rgba(0, 0, 0, 0.08)",
    primaryColorDarker: "rgba(255,255,255,1)",
    primaryColorLighter: "rgba(244,244,244,1)",
    primaryColorLighter2: "rgba(255,255,255,0.75)",
    secondaryColorLighter: "rgba(0,0,0,0.25)",
    secondaryColorLighter2: "rgba(0,0,0,0.75)",
    pageContrast: "rgba(240, 240, 248, 1)",
    homeColor: "#fff",
    buttonBgColor: "rgba(0,0,10,1)",
    buttonColor: "rgba(255,255,255,1)",
}

function setLightThemeRoot() {
    document.documentElement.style.setProperty("--primary-color", lightThemeColors.primaryColor);
    document.documentElement.style.setProperty("--secondary-color", lightThemeColors.secondaryColor);
    document.documentElement.style.setProperty("--tertiary-color", lightThemeColors.tertiaryColor);
    document.documentElement.style.setProperty("--quaternary-color", lightThemeColors.quaternaryColor);
    document.documentElement.style.setProperty("--quinary-color", lightThemeColors.quinaryColor);
    document.documentElement.style.setProperty("--test-background-color", lightThemeColors.testBackgroundColor);
    document.documentElement.style.setProperty("--grey-1", lightThemeColors.grey1);
    document.documentElement.style.setProperty("--boxShadow1", lightThemeColors.boxShadow1);
    document.documentElement.style.setProperty("--underLine", lightThemeColors.underLine);
    document.documentElement.style.setProperty("--primaryColorDarker", lightThemeColors.primaryColorDarker);
    document.documentElement.style.setProperty("--secondaryColorLighter", lightThemeColors.secondaryColorLighter);
    document.documentElement.style.setProperty("--secondaryColorLighter2", lightThemeColors.secondaryColorLighter2);
    document.documentElement.style.setProperty("--pageContrast", lightThemeColors.pageContrast);
    document.documentElement.style.setProperty("--secondaryColorDarker", lightThemeColors.secondaryColorDarker);
    document.documentElement.style.setProperty("--homeColor", lightThemeColors.homeColor);
    document.documentElement.style.setProperty("--boxShadowButton", lightThemeColors.boxShadowButton);
    document.documentElement.style.setProperty("--buttonBgColor", lightThemeColors.buttonBgColor);
    document.documentElement.style.setProperty("--buttonColor", lightThemeColors.buttonColor);
    document.documentElement.style.setProperty("--newPrimaryColor", lightThemeColors.newPrimaryColor);
    document.documentElement.style.setProperty("--primaryColorLighter", lightThemeColors.primaryColorLighter);
    document.documentElement.style.setProperty("--primaryColorLighter2", lightThemeColors.primaryColorLighter2);
    document.documentElement.style.setProperty("--primaryColorOpacityHalf", lightThemeColors.primaryColorOpacityHalf);
    document.documentElement.style.setProperty("--primaryColorOpacityQuarter", lightThemeColors.primaryColorOpacityQuarter);
}

function setDarkThemeRoot() {
    document.body.classList.add("dark-theme");
    document.documentElement.style.setProperty("--primary-color", darkThemeColors.primaryColor);
    document.documentElement.style.setProperty("--secondary-color", darkThemeColors.secondaryColor);
    document.documentElement.style.setProperty("--tertiary-color", darkThemeColors.tertiaryColor);
    document.documentElement.style.setProperty("--quaternary-color", darkThemeColors.quaternaryColor);
    document.documentElement.style.setProperty("--quinary-color", darkThemeColors.quinaryColor);
    document.documentElement.style.setProperty("--test-background-color", darkThemeColors.testBackgroundColor);
    document.documentElement.style.setProperty("--grey-1", darkThemeColors.grey1);
    document.documentElement.style.setProperty("--boxShadow1", darkThemeColors.boxShadow1);
    document.documentElement.style.setProperty("--underLine", darkThemeColors.underLine);
    document.documentElement.style.setProperty("--primaryColorDarker", darkThemeColors.primaryColorDarker);
    document.documentElement.style.setProperty("--secondaryColorLighter", darkThemeColors.secondaryColorLighter);
    document.documentElement.style.setProperty("--secondaryColorLighter2", darkThemeColors.secondaryColorLighter2);
    document.documentElement.style.setProperty("--pageContrast", darkThemeColors.pageContrast);
    document.documentElement.style.setProperty("--secondaryColorDarker", darkThemeColors.secondaryColorDarker);
    document.documentElement.style.setProperty("--homeColor", darkThemeColors.homeColor);
    document.documentElement.style.setProperty("--boxShadowButton", darkThemeColors.boxShadowButton);
    document.documentElement.style.setProperty("--buttonBgColor", darkThemeColors.buttonBgColor);
    document.documentElement.style.setProperty("--buttonColor", darkThemeColors.buttonColor);
    document.documentElement.style.setProperty("--newPrimaryColor", darkThemeColors.newPrimaryColor);
    document.documentElement.style.setProperty("--primaryColorLighter", darkThemeColors.primaryColorLighter);
    document.documentElement.style.setProperty("--primaryColorLighter2", darkThemeColors.primaryColorLighter2);
    document.documentElement.style.setProperty("--primaryColorOpacityHalf", darkThemeColors.primaryColorOpacityHalf);
    document.documentElement.style.setProperty("--primaryColorOpacityQuarter", darkThemeColors.primaryColorOpacityQuarter);
}

export default function ThemeToggle({ styling, sunStyle, moonStyle, mobile }) {
    const { theme, setTheme } = useContext(DataContext);

    const handleThemeChange = () => {
        if (theme === "dark") {
            localStorage.setItem("theme", "light");
            setLightThemeRoot();
            return setTheme("light");
        }

        localStorage.setItem("theme", "dark")
        setDarkThemeRoot();
        return setTheme("dark");
    }

    useEffect(() => {
        const store = localStorage.getItem("theme");

        if (store === "dark") {
            setTheme("dark")
            return setDarkThemeRoot();
        };

        setTheme("light");
        return setLightThemeRoot();
    }, [theme]);

    const className = mobile ? "theme-toggle-container-mobile" : "theme-toggle-container";

    return (
        <div onClick={handleThemeChange} className={styles[className]} style={styling}>
            {/* <Moon style={moonStyle ? moonStyle : { height: "100%" }} /> */}
            {/* <Sun style={sunStyle ? sunStyle : { height: "100%" }} /> */}
            {/* <div className={styles["theme-toggle-thumb"]} style={theme === "light" ? { right: 0 } : { left: 0 }}></div> */}

            {
                !mobile &&
                <>
                    {
                        theme === "dark" &&
                        <Sun style={sunStyle ? sunStyle : { height: "100%", fill: "none" }} />
                    }
                    {
                        theme === "light" &&
                        <Moon style={sunStyle ? sunStyle : { height: "100%", fill: "none" }} />
                    }
                </>
            }

            {
                mobile &&
                <div className={styles.mobileSlider}>
                    <div className={styles.sliderThumb} style={theme === "dark" ? { left: "calc(100% - 42px)" } : {}}></div>
                    <Sun style={sunStyle ? sunStyle : { height: "100%", fill: "none" }} />
                    <Moon style={sunStyle ? sunStyle : { height: "100%", fill: "none" }} />
                </div>
            }
        </div>
    )
}
