import Alcohol from "../components/svgs/Alcohol.js";
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';

import { createContext, useState, useEffect, useRef } from "react";
import { initializeApp } from 'firebase/app';
import { getDatabase, onValue } from 'firebase/database';
import { getStorage, getDownloadURL, ref as sRef } from "firebase/storage";

import Pearson2 from "../components/svgs/Pearson2";
import DivisionAlgorithm from "../pages/courses/Division_Algorithm.js";
const firebaseConfig = {
    apiKey: "AIzaSyBzeg_a3bJIcHaC436BH7i44qpH5_45q10",
    authDomain: "a-level-simulator.firebaseapp.com",
    projectId: "a-level-simulator",
    storageBucket: "a-level-simulator.appspot.com",
    messagingSenderId: "848083984476",
    appId: "1:848083984476:web:955edf800f5f26d8ea4699",
    measurementId: "G-PKNN7YM5XD"
};

const defaultSettings = {
    "paper_filter": [],
    "paper_filters": {
        "AQA": [],
        "Edexcel": [],
        "CIE": [],
        "OCR": [],
    },
    "question_filter": ["multiple", "written"],
    "question_filter_value": "All",
    "exam_board_filter": [],
    "level_filter": ["A"],
    "level_filter_value": "A Level",
    "algorithm": "Sequential",
    "subject": "chemistry",
};

const defaultTrainingData = {
    centres: [
        {
            id: "chemical_nomenclature",
            statistics: {
                total: 0,
                correct: 0,
                incorrect: 0,
                skipped: 0,
            }
        },
        {
            id: "priority_groups_higher_or_lower?",
            statistics: {
                total: 0,
                correct: 0,
                incorrect: 0,
                skipped: 0,
            }
        },
        {
            id: "differentiation_integration",
            statistics: {
                total: 0,
                correct: 0,
                incorrect: 0,
                skipped: 0,
            }
        },
        {
            id: "enthalpy_changes_definitions",
            statistics: {
                total: 0,
                correct: 0,
                incorrect: 0,
                skipped: 0,
            }
        }
    ]
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const storage = getStorage(app);

const DataContext = createContext();

// async function importAll(r) {
//     const keys = r.keys();
//     const minIndex = keys.findIndex(item => item.slice(2) === "Physics_AS_Level_Edexcel_Unit_3B_2010_May_Mark_Scheme_3_a_i.png");

//     for (let i = 0; i < keys.length; i++) {
//         if (i <= minIndex) {
//             console.log(`Skipping ${i}`);
//             continue;
//         };

//         const key = keys[i];
//         images[key] = r(key);

//         // Get the image as a Blob object
//         const response = await fetch(r(key));
//         const blob = await response.blob();

//         // Create a storage reference
//         const storageRef = sRef(storage, `diagrams/${key.slice(2)}`);

//         // Upload the image to Firebase Storage and monitor the upload progress
//         const uploadTask = uploadBytesResumable(storageRef, blob);
//         uploadTask.on('state_changed',
//             (snapshot) => {
//                 const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 if (progress >= 5) {
//                     console.log('Upload for ' + key.slice(2) + ' is ' + progress + '% done');
//                 }
//             },
//             (error) => {
//                 console.error(error);
//             },
//             () => {
//                 console.log('Uploaded a blob or file!');
//             }
//         );
//         await new Promise(resolve => uploadTask.on('state_changed', null, null, resolve));
//     }
// }

// importAll(require.context("../diagrams", false, /\.(png|jpe?g|svg)$/));

const DEFAULT_FONT = "Helvetica";
const DEFAULT_FONT_SIZE_MOBILE = "1.2em";
const DEFAULT_FONT_SIZE_TABLET = "1.4em";
const DEFAULT_FONT_SIZE_DESKTOP = "1.5em";

export const DataContextProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
    const [subject, setSubject] = useState({
        name: "chemistry",
        display: "Chemistry",
        supported: true,
    });

    const [usedFreeQuestions, setUsedFreeQuestions] = useState(false);

    const [user, setUser] = useState(null);

    const [currentVersion, setCurrentVersion] = useState(1.78);

    const [dropdown, setDropdown] = useState(false);

    const [changeSet, setChangeSet] = useState(false);

    const [audioUrl, setAudioUrl] = useState(false);

    const [fontSize, setFontSize] = useState();

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1200px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const DEFAULT_FONT_SIZE = isMobile ? DEFAULT_FONT_SIZE_MOBILE : isTablet ? DEFAULT_FONT_SIZE_TABLET : isDesktop ? DEFAULT_FONT_SIZE_DESKTOP : "1em";

    const [overview, setOverview] = useState([
        {
            title: "Mechanics",
            topics: [
                {
                    title: "Equation of Motion",
                    type: "text",
                    memorise: {
                        flashcards: [
                            {
                                question: "How to find the final velocity from the initial velocity, acceleration, and time?",
                                answer: "v = u + at",
                            },
                            {
                                question: "How to find the displacement from the initial velocity, time, and acceleration?",
                                answer: "s = ut + (1/2)at^2",
                            },
                            {
                                question: "How to find the displacement from the initial velocity, final velocity, and time?",
                                answer: "s = (u+v)/2 * t",
                            },
                            {
                                question: "How to find the final velocity from the initial velocity, acceleration, and displacement?",
                                answer: "v^2 = u^2 + 2as",
                            }
                        ]
                    },
                },
                {
                    title: "Motion Terms",
                },
                {
                    title: "Graphs of Motion",
                },
                {
                    title: "Scalars and Vectors",
                },
                {
                    title: "Resolving Vectors",
                },
                {
                    title: "Adding Vectors",
                },
                {
                    title: "Projectile Motion",
                },
                {
                    title: "Free-body force diagrams",
                },
                {
                    title: "Newton's laws of motion",
                    memorise: {
                        flashcards: [
                            {
                                question: "Define Newton's First Law of Motion",
                                answer: "an object will remain in a state of rest or constant velocity unless acted upon by an external force"
                            },
                            {
                                question: "Define Newton's second Law of Motion",
                                answer: "the net force acting on an object is proportional to the rate of change of the momentum and is in the same direction"
                            },
                            {
                                question: "Define Newton's third Law of Motion",
                                answer: "when two objects interact they exert equal and opposite forces on each other"
                            }
                        ]
                    }
                },
                {
                    title: "Momentum",
                },
                {
                    title: "Principle of conservation of linear momentum",
                },
                {
                    title: "Moments",
                },
                {
                    title: "Work",
                },
                {
                    title: "Energy (kinetic, gravitational, conservation)",
                },
                {
                    title: "Power",
                },
                {
                    title: "Efficiency",
                }
            ]
        },
        {
            title: "Electric Circuits",
            topics: [
                {
                    title: "Ohm's Law (current, voltage, resistance)",
                },
                {
                    title: "Charge conservation",
                },
                {
                    title: "Energy conservation",
                },
                {
                    title: "Kirchoff's Laws",
                    memorise: {
                        flashcards: [
                            {
                                question: "Define Kirchoff's 1st Law",
                                answer: "The sum of the currents entering a junction is the same as the sum of currents exiting a junction"
                            },
                            {
                                question: "Define Kirchoff's 2nd law",
                                answer: "The sum of the EMFs in a closed loop is equal to the sum of the potential differences"
                            },
                            {
                                question: "Define Kirchhoff's 3rd law",
                                answer: ""
                            }
                        ]
                    }
                },
                {
                    title: "Power",
                },
                {
                    title: "Current-Voltage Graphs",
                },
                {
                    title: "Resistivity",
                },
                {
                    title: "Drift Velocity",
                },
                {
                    title: "Potential Divider Circuits",
                },
                {
                    title: "Electromotive Force & Internal Resistance",
                },
                {
                    title: "Resistance with temperature trend",
                },
                {
                    title: "Resistance with light intensity trend",
                },
            ],
        },
        {
            title: "Materials",
            topics: [
                {
                    title: "Density",
                },
                {
                    title: "Upthrust",
                },
                {
                    title: "Stoke's Law",
                },
                {
                    title: "Hooke's Law",
                },
                {
                    title: "Young modulus",
                },
                {
                    title: "Force-extension, force-compression graphs",
                },
                {
                    title: "Stress-strain graphs",
                },
                {
                    title: "Elastic strain energy",
                },
            ],
        },
        {
            title: "Waves and the Particle Nature of Light",
            topics: [
                {
                    title: "Definitions I",
                    memorise: {
                        flashcards: [
                            {
                                question: "Define amplitude",
                                answer: "A wave's maximum displacement from its equilibrium position",
                            },
                            {
                                question: "Define frequency",
                                answer: "The number of complete oscillations passing through a point per second",
                            },
                            {
                                question: "Define time period",
                                answer: "The time taken for one full oscillation",
                            },
                            {
                                question: "Define wave speed",
                                answer: "The distance travelled by a wave per unit time",
                            },
                            {
                                question: "Define wavelength",
                                answer: "The distance between successive crests of a wave",
                            }
                        ]
                    }
                },
                {
                    title: "Waves",
                },
                {
                    title: "Wave Graphs",
                },
                {
                    title: "Definitions II",
                },
                {
                    title: "Interference",
                },
                {
                    title: "Phase difference & Path Difference",
                },
                {
                    title: "Stationary Waves",
                },
                {
                    title: "Speed of transverse waves on a string",
                },
                {
                    title: "Intensity of radiation",
                },
                {
                    title: "Refractive index and Snell's law",
                },
                {
                    title: "Critical Angle",
                },
                {
                    title: "Total Internal Reflection",
                },
                {
                    title: "Measuring refractive index of a solid material",
                },
                {
                    title: "Focal length of lenses",
                },
                {
                    title: "Ray diagrams",
                },
                {
                    title: "Power of a lens",
                },
                {
                    title: "Power of several thin lenses in combination",
                },
                {
                    title: "Real and virtual images",
                },
                {
                    title: "Measuring the power of a lens",
                },
                {
                    title: "Plane Polarisation",
                },
                {
                    title: "Diffraction and Huygen's construction",
                },
                {
                    title: "Diffraction grating equation",
                },
                {
                    title: "Electron diffraction for wave nature of electrons",
                },
                {
                    title: "de Broglie relation",
                },
                {
                    title: "Wave behaviour at an interference",
                },
                {
                    title: "Pulse-echo technique",
                },
                {
                    title: "Wave model and photon model of electromagnetic radiation",
                },
                {
                    title: "Photon energy",
                },
                {
                    title: "Photoelectricity",
                },
                {
                    title: "Threshold frequency, work function, photoelectric equation",
                },
                {
                    title: "Electronvolt",
                },
                {
                    title: "Photoelectric effect as evidence for the particle nature of EM radiation",
                },
                {
                    title: "Atomic line spectra",
                },
            ],
        }
    ])

    const updates = [
        {
            "date": "10/08/23",
            "version": 1.78,
            "update_items": [
                {
                    "update_text": "🛠️ All of AQA Biology added (14 papers) (125 questions)"
                },
                {
                    "update_text": "🛠️ All AQA Chemistry papers (6 papers) (160 questions)"
                }
            ]
        },
        {
            "date": "02/08/23",
            "version": 1.77,
            "update_items": [
                {
                    "update_text": "🛠️ New Interactive specifcations at /specs"
                },
                {
                    "update_text": "🛠️ UI Improvement: many UI fixes, improvements and bug fixes"
                }
            ]
        },
        {
            "date": "21/07/23",
            "version": 1.76,
            "update_items": [
                {
                    "update_text": "🛠️ Overhaul functionality, faster, lighter, cleaner, less errors"
                },
                {
                    "update_text": "🛠️ UI Improvement: new filter UI"
                }
            ]
        },
        {
            "date": "19/07/23",
            "version": 1.75,
            "update_items": [
                {
                    "update_text": "🛠️ New formatting system, latex-like syntax for: fractions, quotients, chemical syntax, bold, italic, small and big text"
                },
                {
                    "update_text": "🛠️ UI Improvement: new font, readability improvements for easier to read texts"
                },
                {
                    "update_text": "🛠️ Higher quality images: higher quality mark scheme and diagram images for new papers, excluding Edexcel"
                }
            ]
        },
        {
            "date": "18/07/23",
            "version": 1.74,
            "update_items": [
                {
                    "update_text": "🛠️ Beginning to add AQA papers, 1 Physics paper added so far"
                },
                {
                    "update_text": "🛠️ UI Improvement: new AQA papers will have dark theme mark scheme images"
                }
            ]
        },
        {
            "date": "27/06/23",
            "version": 1.73,
            "update_items": [
                {
                    "update_text": "🛠️ UI improvement for written questions, greater immersion, ramping up updates & features"
                },
                {
                    "update_text": "🛠️ Bunch of new training centres, also going to get improvements in UI and usability soon"
                },
                {
                    "update_text": "🛠️ Added 2022 set of papers for Physics Edexcel"
                }
            ]
        },
        {
            "date": "28/05/23",
            "version": 1.72,
            "update_items": [
                {
                    "update_text": "🔧 Moved logic to backend, website should now be significantly lighter."
                },
                {
                    "update_text": "🎉 a-level-simulator.com, alevelsimulator.com now valid domains!"
                },
            ]
        },
        {
            "date": "27/05/23",
            "version": 1.71,
            "update_items": [
                {
                    "update_text": "Edexcel Physics is now 100% complete! The last 13.81% has been done. Only a few specimen papers and 5 2022 locked papers not mapped."
                },
                {
                    "update_text": "302 new questions! (old spec) 22 new papers!"
                },
            ]
        },
        {
            "date": "26/05/23",
            "version": 1.70,
            "update_items": [
                {
                    "update_text": "+8.06% Edexcel Physics completion, now only 13.81% left to go (~16 papers: ~3 AS ~13 A)"
                },
                {
                    "update_text": "129 new questions added (old spec)"
                },
                {
                    "update_text": "Small bug fixes & paper fixes"
                },
                {
                    "update_text": "UI improvement: improved answered question color scheme and UI to be easier on the eyes and better."
                },
            ]
        },
        {
            "date": "25/05/23",
            "version": 1.69,
            "update_items": [
                {
                    "update_text": "+7.07% completion for Edexcel Physics, now at 78.13% completion with 21.87% left to cover."
                },
                {
                    "update_text": "113 new questions added! 6 new Edexcel Physics papers (old spec)"
                },
            ]
        },
        {
            "date": "24/05/23",
            "version": 1.68,
            "update_items": [
                {
                    "update_text": "+12.68% completion for Edexcel Physics, now at 71.06% completion with 28.94% left to cover."
                },
                {
                    "update_text": "203 new questions added! 11 new Edexcel Physics papers (old spec)"
                },
                {
                    "update_text": "UI Improvement: more responsive for a better mobile experience"
                },
            ]
        },
        {
            "date": "23/05/23",
            "version": 1.67,
            "update_items": [
                {
                    "update_text": "New paper: AS Level Edexcel June 2016 Unit 2 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel June 2015 Unit 2 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel June 2014 Unit 2 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel June 2013 Unit 2 (old spec) [~18 questions]"
                },
            ]
        },
        {
            "date": "22/05/23",
            "version": 1.66,
            "update_items": [
                {
                    "update_text": "New paper: AS Level Edexcel June 2017 Unit 2 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel June 2012 Unit 1 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel June 2011 Unit 1 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel June 2010 Unit 1 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel June 2009 Unit 1 (old spec) [~18 questions]"
                },
            ]
        },
        {
            "date": "21/05/23",
            "version": 1.65,
            "update_items": [
                {
                    "update_text": "Now practically accessible via mobile devices!"
                },
                {
                    "update_text": "UI Improvement: website is now more responsive allowing for mobile users to use it."
                },
                {
                    "update_text": "UI Improvement: many small UI improvements"
                },
            ]
        },
        {
            "date": "20/05/23",
            "version": 1.64,
            "update_items": [
                {
                    "update_text": "Added logo favicon"
                },
                {
                    "update_text": "UI Improvement: slight improvements in spacing & UI overall"
                },
                {
                    "update_text": "New 'Analysis' page: for now it shows the breakdown of % chance of multiple choice questions, in the future it will be far more extensive."
                },
                {
                    "update_text": "New paper: AS Level Edexcel Physics May 2013 Unit 1 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel Physics January 2013 Unit 1 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel Physics January 2012 Unit 1 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel Physics January 2011 Unit 1 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel Physics January 2010 Unit 1 (old spec) [~18 questions]"
                },
                {
                    "update_text": "New paper: AS Level Edexcel Physics January 2009 Unit 1 (old spec) [~18 questions]"
                },

            ]
        },
        {
            "date": "19/05/23",
            "version": 1.63,
            "update_items": [
                {
                    "update_text": "Bug fix: Fixed settings being reset on refresh."
                },
                {
                    "update_text": "New paper: A Level Edexcel Physics June 2017 Unit 3 (old spec) [18 questions]"
                },
                {
                    "update_text": "New paper: A Level Edexcel Physics June 2016 Unit 3 (old spec) [19 questions]"
                },
                {
                    "update_text": "New paper: A Level Edexcel Physics June 2015 Unit 3 (old spec) [19 questions]"
                },
                {
                    "update_text": "New paper: A Level Edexcel Physics June 2014 Unit 3 (old spec) [19 questions]"
                },
                {
                    "update_text": "Functionality Improvement: Improved the paper filter system in /rapid again; now you can batch select and de-select by year"
                },
                {
                    "update_text": "UI Improvment: intiutive next question button which takes up less space."
                },
            ]
        },
        {
            "date": "18/05/23",
            "version": 1.62,
            "update_items": [
                {
                    "update_text": "+~16 new written questions from Edexcel Physics Paper 1 & 2 May 2019 (new spec)"
                },
                {
                    "update_text": "Improved the paper filter system in /rapid; it's now organized by year for ease of navigation."
                },
            ]
        },
        {
            "date": "17/05/23",
            "version": 1.61,
            "update_items": [
                {
                    "update_text": "+75 new written questions from an array of Edexcel Physics past papers (new spec)"
                },
                {
                    "update_text": "+5% Edexcel Physics completion, ~65% left to go"
                },
                {
                    "update_text": "Fixed scaling issue for config at the top of rapid"
                },
                {
                    "update_text": "Added controls info to home page"
                },
                {
                    "update_text": "New training centre: Enthalpy"
                },
            ]
        },
        {
            "date": "16/05/23",
            "version": 1.6,
            "update_items": [
                {
                    "update_text": "New Paper: A Level Edexcel Physics June 2021 Paper 3 [12 questions]"
                },
                {
                    "update_text": "New Paper: A Level Edexcel Physics June 2020 Paper 3 [12 questions]"
                },
                {
                    "update_text": "New Paper: A Level Edexcel Physics June 2019 Paper 3 [12 questions]"
                },
            ]
        },
        {
            "date": "15/05/23",
            "version": 1.5,
            "update_items": [
                {
                    "update_text": "New Paper: A Level Edexcel Physics June 2018 Paper 3 [13 questions]"
                },
                {
                    "update_text": "New Paper: AS Level Edexcel Physics June 2018 Paper 1 [16 questions]"
                },
                {
                    "update_text": "New Paper: AS Level Edexcel Physics June 2018 Paper 2 [16 questions]"
                },
                {
                    "update_text": "Source buttons for paper and mark scheme at bottom of questions in /rapid"
                },
                {
                    "update_text": "Added list of major upcoming features to /home"
                }
            ]
        },
        {
            "date": "14/05/23",
            "version": 1.4,
            "update_items": [
                {
                    "update_text": "New Paper: A Level Edexcel Physics June 2017 Paper 1 [18 questions]"
                },
                {
                    "update_text": "New Paper: A Level Edexcel Physics June 2017 Paper 2 [20 questions]"
                },
                {
                    "update_text": "New Paper: A Level Edexcel Physics June 2017 Paper 3 [13 questions]"
                },
                {
                    "update_text": "New update notifier"
                }
            ]
        },
        {
            "date": "13/05/23",
            "version": 1.3,
            "update_items": [
                {
                    "update_text": "New Paper: AS Level Edexcel Physics June 2016 Paper 1 [16 questions]"
                },
                {
                    "update_text": "New Paper: AS Level Edexcel Physics June 2016 Paper 2 [16 questions]"
                }
            ]
        },
        {
            "date": "11/05/23",
            "version": 1.2,
            "update_items": [
                {
                    "update_text": "New Paper: AS Level Edexcel Physics June 2017 Paper 1 [16 questions]"
                },
                {
                    "update_text": "New Paper: AS Level Edexcel Physics June 2017 Paper 2 [17 questions]"
                },
                {
                    "update_text": "Bug fixes"
                }
            ]
        },
        {
            "date": "04/05/23",
            "version": 1.1,
            "update_items": [
                {
                    "update_text": "New Paper: A Level Edexcel Physics International January 2022 Unit 4 [18 questions]"
                },
                {
                    "update_text": "New Paper: A Level Edexcel Physics International January 2022 Unit 5 [18 questions]"
                }
            ]
        },
        {
            "date": "03/05/23",
            "version": 1.0,
            "update_items": [
                {
                    "update_text": "Implemented auto-updating progress tracker"
                },
                {
                    "update_text": "UI improvement: new updates UI"
                },
                {
                    "update_text": "New Paper: A Level Edexcel Physics International January 2022 Unit 1 [18 questions]"
                },
                {
                    "update_text": "New Paper: A Level Edexcel Physics International January 2022 Unit 2 [18 questions]"
                },
                {
                    "update_text": "New Paper: A Level Edexcel Physics International January 2022 Unit 3 [18 questions]"
                }
            ]
        },
        {
            "date": "30/04/23",
            "version": 0.9,
            "update_items": [
                {
                    "update_text": "Added Chemistry Edexcel May 2018 AS Level Paper 2"
                },
                {
                    "update_text": "Added Chemistry Edexcel May 2019 AS Level Paper 2"
                },
                {
                    "update_text": "Added Chemistry Edexcel May 2019 AS Level Paper 1"
                }
            ]
        },
        {
            "date": "29/04/23",
            "version": 0.8,
            "update_items": [
                {
                    "update_text": "Added Chemistry Edexcel May 2020 AS Level Paper 1"
                },
                {
                    "update_text": "Added Chemistry Edexcel November 2021 AS Level Paper 2"
                },
                {
                    "update_text": "Added Chemistry Edexcel November 2021 AS Level Paper 1"
                },
                {
                    "update_text": "Completely added Chemistry Edexcel November 2021 A Level Paper 1"
                }
            ]
        },
        {
            "date": "28/04/23",
            "version": 0.7,
            "update_items": [
                {
                    "update_text": "You can now filter by exam board and filter by level"
                },
                {
                    "update_text": "Bug fixes, UI improvements"
                }
            ]
        },
        {
            "date": "27/04/23",
            "version": 0.6,
            "update_items": [
                {
                    "update_text": "Added 18 new questions, 10 multiple choice and 8 written from International A Level Edexcel Unit 2 October 2020"
                },
                {
                    "update_text": "Added filtering by specific papers in Rapid, bug fixes as well."
                }
            ]
        },
        {
            "date": "26/04/23",
            "version": 0.5,
            "update_items": [
                {
                    "update_text": "UI Overhaul, bug fixes and functionality improvements, new button to generate new question, new training centres under development"
                }
            ]
        },
        {
            "date": "23/04/23",
            "version": 0.4,
            "update_items": [
                {
                    "update_text": "Added Training Centres - Practice and master Chemical Nomenclature"
                }
            ]
        },
        {
            "date": "22/04/23",
            "version": 0.3,
            "update_items": [
                {
                    "update_text": "Improved and built upon algorithm, more customizable and more efficient for learning."
                },
                {
                    "update_text": "Added 10 new multiple choice questions for Edexcel Physics A Level Paper 2 2018"
                },
                {
                    "update_text": "Added 10 new multiple choice questions for Edexcel Physics A Level Paper 1 2018"
                },
                {
                    "update_text": "Added 8 new multiple choice questions for Edexcel Physics AS Level Paper 2 2019"
                },
                {
                    "update_text": "Added 8 new multiple choice questions for Edexcel Physics AS Level Paper 1 2019"
                }
            ]
        },
        {
            "date": "21/04/23",
            "version": 0.2,
            "update_items": [
                {
                    "update_text": "Added 10 new multiple choice questions for Edexcel Physics A Level Paper 2 2019"
                },
                {
                    "update_text": "Added 10 new multiple choice questions for Edexcel Physics A Level Paper 1 2019"
                }
            ]
        },
        {
            "date": "20/04/23",
            "version": 0.1,
            "update_items": [
                {
                    "update_text": "Added 10 new multiple choice questions for Edexcel Physics A Level Paper 2 2019"
                },
                {
                    "update_text": "Added 10 new multiple choice questions for Edexcel Physics A Level Paper 1 2019"
                },
                {
                    "update_text": "Added algorithm to reduce repetition of same questions and expose to more unseen questions"
                }
            ]
        }
    ];

    const [trainingCentres, setTrainingCentres] = useState([

    ]);

    const [courses, setCourses] = useState([
        {
            title: "Number Theory",
            subject: "Further Maths",
            path: "number_theory",
            statistics: {
                total: 0,
                correct: 0,
                incorrect: 0,
                skipped: 0,
            },
            modules: [
                {
                    title: "Division Algorithm",
                    path: "division_algorithm",
                    statistics: {
                        total: 0,
                        correct: 0,
                        incorrect: 0,
                        skipped: 0,
                    },
                    handler: <DivisionAlgorithm />
                },
            ]
        }
    ]);

    const [supportedSubjects, setSupportedSubjects] = useState([
        {
            name: "physics",
            display: "Physics",
            supported: true,
        },
        {
            name: "mathematics",
            display: "Mathematics",
            supported: true,
        },
        {
            name: "chemistry",
            display: "Chemistry",
            supported: true,
        },
        {
            name: "biology",
            display: "Biology",
            supported: true,
        }
    ]);

    const [supportedExamBoards, setSupportedExamBoards] = useState([
        {
            name: "aqa",
            display: "AQA",
            supported: true,
        },
        {
            name: "edexcel",
            display: "Edexcel",
            supported: true,
            logo: <Pearson2 styling={{ height: "40px", width: "40px" }} />
        },
        {
            name: "cie",
            display: "CIE",
            supported: false,
        }
    ]);

    const [supportedLevels, setSupportedLevels] = useState([
        {
            name: "A",
            display: "A Level",
            supported: true,
        },
        {
            name: "AS",
            display: "AS Level",
            supported: true,
        }
    ]);

    const [examBoard, setExamBoard] = useState(localStorage.getItem("exam_board") || "AQA");

    useEffect(() => {
        const fileRef = sRef(storage, 'tts/output.mp3');
        getDownloadURL(fileRef).then((url) => {
            setAudioUrl(url);
        }).catch((error) => {
        });

        let theme = localStorage.getItem("theme");
        let subject = localStorage.getItem("subject");
        let settings = localStorage.getItem("settings");
        let trainingData = localStorage.getItem("training_data");

        if (localStorage.getItem("data")) {
            if (parseFloat(JSON.parse(localStorage.getItem("data")).last_version_seen) < 1.76) {
                localStorage.setItem("settings", JSON.stringify(defaultSettings));
            }
        }

        if (!settings) {
            localStorage.setItem("settings", JSON.stringify(defaultSettings));
        };

        if (!trainingData) {
            localStorage.setItem("training_data", JSON.stringify(defaultTrainingData));
        };

        try {
            const parsed = JSON.parse(settings);
        } catch (error) {
            localStorage.setItem("settings", JSON.stringify(defaultSettings));
        }

        if (theme) {
            setTheme(theme);
        } else {
            localStorage.setItem("theme", "dark");
        }

        if (subject) {
            setSubject(JSON.parse(subject));
        } else {
            localStorage.setItem("subject", JSON.stringify({
                name: "chemistry",
                display: "Chemistry",
                supported: true,
            },));
        }
    }, []);

    const [settings, setSettings] = useState(localStorage.getItem("settings") ? JSON.parse(localStorage.getItem("settings")) : defaultSettings);
    const [trainingData, setTrainingData] = useState(localStorage.getItem("training_data") ? JSON.parse(localStorage.getItem("training_data")) : defaultTrainingData);

    const [papers, setPapers] = useState("loading");

    const levels = {
        "Advanced": "A",
        "Advanced Subsidiary": "AS",
        "GCSE": "GCSE",
    };

    useEffect(() => {
        const fetchQuestionData = async () => {
            try {
                let subject = defaultSettings.subject;

                try {
                    subject = JSON.parse(localStorage.getItem("subject")).display;
                } catch {

                };
                // console.log(`${examBoard}_${subject.display}`)
                // const response = await import(`./${examBoard}_${subject.display}.json`);
                const response = await import(`../context/${examBoard}/${settings.level_filter[0]}/${subject}/${examBoard}_${settings.level_filter[0]}_${subject}_Questions.json`);
                const jsonData = await response.default;
                // console.log(jsonData);
                setPapers(jsonData);
            } catch (error) {
                setPapers([])
                console.log(`Failed to fetch question data for ${examBoard} ${subject.display}:`, error);
            }
        };

        fetchQuestionData();
    }, [examBoard, subject]);

    const [chemData, setChemData] = useState([
    ]);

    return (
        <DataContext.Provider value={{
            papers,
            setPapers,
            theme,
            setTheme,
            subject,
            setSubject,
            supportedSubjects,
            setSupportedSubjects,
            settings,
            setSettings,
            trainingCentres,
            setTrainingCentres,
            chemData,
            setChemData,
            user,
            setUser,
            usedFreeQuestions,
            setUsedFreeQuestions,
            updates,
            currentVersion,
            dropdown,
            setDropdown,
            storage,
            supportedExamBoards,
            changeSet,
            setChangeSet,
            examBoard,
            setExamBoard,
            supportedLevels,
            setSupportedLevels,
            audioUrl,
            setAudioUrl,
            trainingData,
            setTrainingData,
            courses,
            overview,
            setOverview,
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;