import React, { useContext, useEffect } from 'react';
import LatexContext from './LatexContext';
import Fraction from "./LatexHandlers/Fraction"
import styles from "./Latex.module.css";
import Sum from './LatexHandlers/Sum';
import Subscript from './LatexHandlers/Subscript';
import Superscript from './LatexHandlers/Superscript';
import Input from './LatexHandlers/Input';
import Root from './LatexHandlers/Root';
import Integral from './LatexHandlers/Integral';

const specialSymbols = {
    '*': '×',
    '->': '→',
    '<-': '←',
    'arru': '↑',
    'arrd': '↓',
    '<->': '⇌',
    'alpha': 'α',
    'beta': 'β',
    'gamma': 'γ',
    'delta': 'δ',
    'epsilon': 'ε',
    'zeta': 'ζ',
    'eta': 'η',
    'theta': 'θ',
    'iota': 'ι',
    'kappa': 'κ',
    'lambda': 'λ',
    'mu': 'μ',
    'nu': 'ν',
    'xi': 'ξ',
    'omicron': 'ο',
    'pi': 'π',
    'rho': 'ρ',
    'sigma': 'σ',
    'tau': 'τ',
    'upsilon': 'υ',
    'phi': 'φ',
    'chi': 'χ',
    'psi': 'ψ',
    'omega': 'ω',
    'Alpha': 'Α',
    'Beta': 'Β',
    'Gamma': 'Γ',
    'Delta': 'Δ',
    'Epsilon': 'Ε',
    'Zeta': 'Ζ',
    'Eta': 'Η',
    'Theta': 'Θ',
    'Iota': 'Ι',
    'Kappa': 'Κ',
    'Lambda': 'Λ',
    'Mu': 'Μ',
    'Nu': 'Ν',
    'Xi': 'Ξ',
    'Omicron': 'Ο',
    'Pi': 'Π',
    'Rho': 'Ρ',
    'Sigma': 'Σ',
    'Tau': 'Τ',
    'Upsilon': 'Υ',
    'Phi': 'Φ',
    'Chi': 'Χ',
    'Psi': 'Ψ',
    'Omega': 'Ω',
    'degree': '°',
    'tick': '✓',
    'standardconditions': '⦵',
    'natural': 'ℕ',     // Natural numbers
    'integer': 'ℤ',     // Integers
    'rational': 'ℚ',    // Rational numbers
    'real': 'ℝ',        // Real numbers
    'complex': 'ℂ',     // Complex numbers
    'prime': 'ℙ',       // Prime numbers
    'emptyset': '∅',    // Empty set symbol
    'in': '∈',          // Element of
    'notin': '∉',       // Not an element of
    'subset': '⊆',      // Subset
    'superset': '⊇',    // Superset
    'union': '∪',       // Union
    'intersection': '∩', // Intersection
    // Add any other Greek letters or special symbols here...
};

const commands = ['frac', 'bold', 'italic', 'math', 'newline', 'int' /* other supported commands here */];

const braces = {
    'frac': 2,
    'bold': 1,
    'italic': 1,
};

function replaceSpecialSymbols(latexString) {
    for (let symbol in specialSymbols) {
        latexString = latexString.split("\\" + symbol).join(specialSymbols[symbol]);
    }
    return latexString;
};

function getCommandArguments(latex, command) {
    let bracesCount = 0;
    let i = 0;
    let prev;
    let start;
    const args = [];
    const maxArgs = braces[command];

    for (; i < latex.length; i++) {
        if (latex[i] === '{' && prev !== '\\') {
            if (bracesCount === 0) {
                // The beginning of the content inside the braces
                start = i + 1;
            }
            bracesCount++;
        } else if (latex[i] === '}' && prev !== '\\') {
            bracesCount--;
            if (bracesCount === 0) {
                // The end of the content inside the braces
                args.push(latex.substring(start, i));
                if (args.length === maxArgs) {
                    // Stop extracting arguments when we have reached the maximum number
                    break;
                }
                bracesCount = 0; // Reset braces count for the next argument
            }
        };
        prev = latex[i];
    }

    return {
        args,
        remaining: latex.slice(i + 1)
    };
};

export const LatexFormatter = ({ id, text, decompile = false, element = <span>c</span>, pos = 0, rangeStyle }) => {
    // element is if the user wants to insert a new element at some position
    // elementPos is the position where the new element is to be inserted

    // range is the range of the selected nodes
    // rangeStyle is the applied style to the selected nodes

    const cursor = <span key={id + "text-cursor-key"} id={id + "-canvas-input-cursor"} className={styles.blinkingCursor}>​</span>

    const renderLatex = (node) => {
        switch (node?.command) {
            case "frac":
                const fractionStyle = {
                    display: "inline-flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                };

                const numeratorStyle = {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor: "red",
                    width: "100%",
                    flex: 1,
                };

                const denominatorStyle = {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor: "blue",
                    flex: 1,
                };

                const dividerStyle = {
                    width: "100%",
                    height: "1px",
                    borderTop: "1px solid #fff",
                };

                return (
                    <div function={node.command} style={fractionStyle}>
                        <div style={numeratorStyle}>
                            {renderLatex(node.children[0])}
                        </div>
                        <div style={dividerStyle}></div>
                        <div style={denominatorStyle}>
                            {renderLatex(node.children[1])}
                        </div>
                    </div>
                )
            case "bold":
                const boldStyle = {
                    fontWeight: "bold",
                };

                return (
                    <span style={boldStyle}>
                        {renderLatex(node.children[0])}
                    </span>
                );
            case "italic":
                const italicStyle = {
                    fontStyle: "italic",
                };

                return (
                    <span style={italicStyle}>
                        {renderLatex(node.children[0])}
                    </span>
                );
            case "text":
                const textStyle = {
                    display: "initial",
                };

                return (
                    <span>
                        {node.latexText.split("").map((char, i) => (
                            <>
                                {/* {node.startIndex === elementPos && cursor} */}
                                <span key={node.id + "-" + i} id={node.id + "-" + i} className={styles.latexText} style={textStyle}>{char}</span>
                            </>
                        ))}
                    </span>
                );

                return <span key={node.id} id={node.id} className={styles.latexText} style={textStyle}>{node.latexText}</span>;
            case "cursor":
                return (
                    cursor
                )
                break;
            default:
                if (!node?.command) return <span>error</span>
                return (
                    <>
                        {node.children ?
                            node.children.map((child, i) => (
                                renderLatex(child)
                            ))
                            :
                            node.latexText
                        }
                    </>
                )
        };
    };

    const createNode = (command, latexText, args, startIndex, endIndex, path, nodeCount, children = []) => {
        return {
            command,
            latexText,
            startIndex,
            endIndex,
            path,
            children,
            id: `${id}-${startIndex}-${endIndex}`,
            pos: nodeCount,
        }
    };

    function handleLatexCommand(command, args, path, indexCounter, nodeCounter) {
        let latexText = args.join('');

        if (command !== 'text') {
            latexText = `\\${command}${args.map(arg => `{${arg}}`).join('')}`;
        };

        const startIndex = indexCounter; // 1 for the \ and 1 for the {
        const endIndex = indexCounter + latexText.length; // 1 for the last } and 1 for the \ before the command

        let nodeCount = nodeCounter + 1;
        let node = createNode(command, latexText, args, startIndex, endIndex, path, nodeCount);

        let total = 0;

        if (command !== 'text') {
            args.forEach((arg, i) => {
                let children = parseLatex(arg, startIndex + command.length + total + 2, [...path, i, 0]).children;
                if (children.length === 0) {
                    children = [
                        { command: "text", latexText: "" }
                    ];
                };
                node.children.push(createNode("container", arg, [arg], startIndex + command.length + total + 2, startIndex + command.length + total + arg.length + 1, [...path, i], nodeCount, children));
                total += arg.length + 2;
            });
        };

        return { node, updatedCounter: endIndex, updatedNodeCounter: nodeCount };
    };

    const recurser = (array, counter, target, cursor, position = 0) => {
        for (let i = 0; i < array.length; i++) {
            if (counter === target) {
                array.splice(i, 0, cursor);
                cursor = false;
                counter++;
                position = array[i + 1].endIndex;
                return { counter, position };
            } else if (i === array.length - 1) {
                if (counter + 1 === target && !array[i]?.children?.length) {
                    array.push(cursor);
                    cursor = false;
                    counter++;
                    position = array[i].endIndex;
                    return { counter, position };
                };
            };

            counter++;

            if (array[i]?.children?.length) {
                for (let j = 0; j < array[i].children.length; j++) {
                    if (array[i].children[j].children.length === 0) {

                    };

                    const iteration = recurser(array[i].children[j].children, counter, target, cursor);
                    position = iteration.position;
                    counter = iteration.counter;
                    counter++;
                };
            };

            if (i === array.length - 1 && counter === target) {
                array.push(cursor);
                cursor = false;
                position = array[i].endIndex;
                return { counter, position };
            };
        };

        return { counter, position };
    };

    const findClosestCursor = (tree) => {
        // Loop through children to find a cursor or dive deeper
        for (let i = 0; i < tree.children.length; i++) {
            const child = tree.children[i];
            if (child.command === "cursor") {
                // If the cursor is the first child, return the next sibling's start index
                // If no next sibling, return `undefined` or an alternative logic
                if (i === 0) {
                    return tree.children[i + 1] ? tree.children[i + 1].startIndex : undefined;
                };
                // Otherwise, return the previous sibling's end index
                return tree.children[i - 1].endIndex;
            };
            // If the child has its own children, dive into them recursively
            if (child.children && child.children.length) {
                const foundCursor = findClosestCursor(child);
                // If a cursor index was found in the subtree, return it
                if (foundCursor !== undefined) {
                    return foundCursor;
                };
            };
        };
        // If no cursor found in the entire tree/subtree, return undefined
        return undefined;
    };

    const calculateMax = (array, counter, target, cursor) => {
        for (let i = 0; i < array.length; i++) {
            counter++;
            if (array[i]?.children?.length) {
                for (let j = 0; j < array[i].children.length; j++) {
                    counter = calculateMax(array[i].children[j].children, counter, target, cursor);
                    counter++;
                };
            };
        };

        return counter;
    };

    function parseLatex(latex, passedIndexCounter = 0, passedPath = [0], passedNodeCount = -1) {
        const tree = { command: "document", children: [], leaves: [] };

        let path = passedPath;
        let indexCounter = passedIndexCounter;
        let nodeCount = passedNodeCount;

        let index = 0;

        while (latex.length > 0) {
            index++;
            let regex = new RegExp(`\\\\(${commands.join('|')})`, 'g');
            let commandMatch;

            let command = "text";
            let args = [];

            // everything before the match is text
            if ((commandMatch = regex.exec(latex)) !== null) {
                let text = latex.slice(0, commandMatch.index).split("");

                if (text.length > 0) {
                    text.forEach((char, i) => {
                        args = [char];

                        const { node, updatedCounter, updatedNodeCounter } = handleLatexCommand(command, args, [...path], indexCounter, nodeCount);

                        indexCounter = updatedCounter;
                        tree.children.push(node);
                        path[path.length - 1]++;
                        nodeCount = updatedNodeCounter;
                    });
                };

                command = commandMatch[0].slice(1);
                const commandArgs = getCommandArguments(latex.slice(commandMatch.index + command.length + 1), command);
                args = commandArgs.args;
                latex = commandArgs.remaining;
            } else {
                console.log('there');
                const wordsWithSpaces = latex.split("");

                wordsWithSpaces.forEach((char, i) => {
                    args = [char];
                    const { node, updatedCounter, updatedNodeCounter } = handleLatexCommand(command, args, [...path], indexCounter, nodeCount);

                    indexCounter = updatedCounter;
                    tree.children.push(node);
                    path[path.length - 1]++;
                    nodeCount = updatedNodeCounter;
                });

                args = [];
                latex = "";
            };

            if (args.length) {
                const { node, updatedCounter, updatedNodeCounter } = handleLatexCommand(command, args, [...path], indexCounter, nodeCount);

                path[path.length - 1]++;
                indexCounter = updatedCounter;
                nodeCount = updatedNodeCounter;
                tree.children.push(node);
            };
        };

        return tree;
    };

    const preProcessedText = replaceSpecialSymbols(text);
    const preProcessedText2 = preProcessedText.split("-").join("−");
    const preProcessedText3 = preProcessedText2.split("\n").join("\\newline");

    let tree = parseLatex(preProcessedText3);

    const maxPos = calculateMax(tree.children, 0, pos, { content: "yo" });
    const cursorPlaced = recurser(tree.children, 0, pos, { command: "cursor" });

    // console.log(tree, findClosestCursor(tree, 0));

    const render = renderLatex(tree);

    const overallStyle = {
        display: "flex",
        // flexDirection: "row",
        // justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "fit-content",
        width: "fit-content",
        marginLeft: "20px"
    };

    console.log(tree);

    if (decompile) {
        return render;
    };

    return {
        maxPos: maxPos,
        cursor: findClosestCursor(tree, 0),
        tree: tree,
        render: <div style={overallStyle}>
            {render}
        </div>
    }

    return (
        <div style={overallStyle}>
            {render}
        </div>
    );
};

export default LatexFormatter;