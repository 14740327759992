import { AlertTriangle, FileIcon, FileInput, LucideBold, LucideCheck, LucideChevronDown, LucideEdit, LucideFormInput, LucideHammer, LucideImage, LucideIndent, LucideItalic, LucideLineChart, LucideMinus, LucideOutdent, LucidePaintbrush, LucidePlus, LucideTable, LucideTag, LucideText, LucideTextCursorInput, LucideTrash, LucideType, LucideX, StarIcon, UserSquare } from "lucide-react";
import styles from "./StatefulLatexEditor.module.css";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import LatexFormatter from "../Latex/LatexFormatter";

const SELECT_DELAY = 125;

const keyFilter = [
    "Meta",
    "Control",
    "Shift",
    "Alt",
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "CapsLock",
    "Escape",
];

function getCharacterRects(element) {
    let rects = [];
    if (element.childNodes.length > 0) {
        const textNode = element.firstChild; // Assuming the first child is the text node
        const text = textNode.nodeValue;

        for (let i = 0; i < text.length; i++) {
            const range = document.createRange();
            range.setStart(textNode, i);
            range.setEnd(textNode, i + 1);

            // Using getBoundingClientRect() to get the rect for the single character
            const rect = range.getBoundingClientRect();
            rects.push(rect);
        }
    }
    return rects;
};

function getCharacterRects2(element) {
    let rects = [];

    function processNode(node) {
        if (node.nodeType === Node.TEXT_NODE) {
            const text = node.nodeValue.replace(/​/g, "");

            for (let i = 0; i < text.length; i++) {
                const range = document.createRange();
                range.setStart(node, i);
                range.setEnd(node, i + 1);

                const rect = range.getBoundingClientRect();
                rects.push(rect);
            }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            Array.from(node.childNodes).forEach(processNode);
        }
    }

    processNode(element);

    return rects;
};

function findClickedLine(lines, clientX, clientY, id) {
    let lineIndex = -1; // Default to -1 to indicate no line found

    for (let i = 0; i < lines.length; i++) {
        const domLine = document.getElementById(id + "-latex-line-" + i);
        if (!domLine) continue; // Skip iteration if domLine is not found

        const rect = domLine.getBoundingClientRect(); // Get dimensions and position

        const topLeft = { x: rect.left, y: rect.top };
        const bottomRight = { x: rect.right, y: rect.bottom };

        // Check if the click is within the bounds of the line
        if (clientX >= topLeft.x && clientX <= bottomRight.x && clientY >= topLeft.y && clientY <= bottomRight.y) {
            lineIndex = i; // Store the index of the line clicked inside of
            break; // Exit the loop once the correct line is found
        }
    }

    return lineIndex; // Return the index of the line, or -1 if no line was clicked
};

function findClickedLine2(lines, path, id) {
    let lineIndex = -1; // Default to -1 to indicate no line found

    for (let i = 0; i < lines.length; i++) {
        // Check if the click is within the bounds of the line
        if (path[0] >= lines[i].startIndex && path[0] <= lines[i].endIndex) {
            lineIndex = i; // Store the index of the line clicked inside of
            break; // Exit the loop once the correct line is found
        }
    }

    return lineIndex; // Return the index of the line, or -1 if no line was clicked
};

/* CASE 1:
    \frac{1}{2}test
    \frac{1}{2}_test + space
    \frac{1}{2}test  + backspace
    does not go to start of text but jumps to denominator
*/

const specialSymbols = {
    '*': '×',
    '->': '→',
    '<-': '←',
    'arru': '↑',
    'arrd': '↓',
    '<->': '⇌',
    'alpha': 'α',
    'beta': 'β',
    'gamma': 'γ',
    'delta': 'δ',
    'epsilon': 'ε',
    'zeta': 'ζ',
    'eta': 'η',
    'theta': 'θ',
    'iota': 'ι',
    'kappa': 'κ',
    'lambda': 'λ',
    'mu': 'μ',
    'nu': 'ν',
    'xi': 'ξ',
    'omicron': 'ο',
    'pi': 'π',
    'rho': 'ρ',
    'sigma': 'σ',
    'tau': 'τ',
    'upsilon': 'υ',
    'phi': 'φ',
    'chi': 'χ',
    'psi': 'ψ',
    'omega': 'ω',
    'Alpha': 'Α',
    'Beta': 'Β',
    'Gamma': 'Γ',
    'Delta': 'Δ',
    'Epsilon': 'Ε',
    'Zeta': 'Ζ',
    'Eta': 'Η',
    'Theta': 'Θ',
    'Iota': 'Ι',
    'Kappa': 'Κ',
    'Lambda': 'Λ',
    'Mu': 'Μ',
    'Nu': 'Ν',
    'Xi': 'Ξ',
    'Omicron': 'Ο',
    'Pi': 'Π',
    'Rho': 'Ρ',
    'Sigma': 'Σ',
    'Tau': 'Τ',
    'Upsilon': 'Υ',
    'Phi': 'Φ',
    'Chi': 'Χ',
    'Psi': 'Ψ',
    'Omega': 'Ω',
    'degree': '°',
    'tick': '✓',
    'standardconditions': '⦵',
    'natural': 'ℕ',     // Natural numbers
    'integer': 'ℤ',     // Integers
    'rational': 'ℚ',    // Rational numbers
    'real': 'ℝ',        // Real numbers
    'complex': 'ℂ',     // Complex numbers
    'prime': 'ℙ',       // Prime numbers
    'emptyset': '∅',    // Empty set symbol
    'in': '∈',          // Element of
    'notin': '∉',       // Not an element of
    'subset': '⊆',      // Subset
    'superset': '⊇',    // Superset
    'union': '∪',       // Union
    'intersection': '∩', // Intersection
    'notequal': '≠',    // Not equal to
    'cdot': '·',        // Middle dot
    'divides': '∣',     // Divides
    'notdivides': '∤',  // Does not divide
    // Add any other Greek letters or special symbols here...
};

function replaceSpecialSymbols(latexString) {
    for (let symbol in specialSymbols) {
        latexString = latexString.split("\\" + symbol).join(specialSymbols[symbol]);
    }
    return latexString;
};

export default function StatefulLatexEditor({ text, lineWidth, minLines, maxLines, id, editable }) {
    let preProcessedText = replaceSpecialSymbols(text);
    preProcessedText = preProcessedText.replace(/[\r\n]/g, "");
    preProcessedText = preProcessedText.replace(/\s{2,}/g, " ");
    text = preProcessedText.trim();

    // canvas related
    const questionCanvas = useRef(text ? text : '');
    const cursor = editable ? <span key={id + "text-cursor-key"} id={id + "-canvas-input-cursor"} className={styles.blinkingCursor}>​</span> : <></>;

    const [canvas, setCanvas] = useState({});
    const [canvasByLines, setCanvasByLines] = useState({});
    const [canvasFlatTree, setCanvasFlatTree] = useState([]);
    const calculatedLineWidth = useRef(null);

    // cursor related
    const [nodePath, setNodePath] = useState([0]);
    const [nodeId, setNodeId] = useState('');
    const [prevNodePath, setPrevNodePath] = useState([0]);

    const [canvasIndex, setCanvasIndex] = useState(0);
    const [selection, setSelection] = useState([]);
    const selectionTails = useRef(null);

    const history = useRef({ actions: [], cursor: 0 });

    // selection related
    const [selecting, setSelecting] = useState(false);
    const selectionStart = useRef(null);
    const selectionTimeout = useRef(null);
    const mouseDownTime = useRef(null);
    const epicenterSelect = useRef(null);

    useEffect(() => {
        const { expressionalLatex } = LatexFormatter({
            text: questionCanvas.current,
            id,
        });

        console.log(expressionalLatex);

        setCanvas(expressionalLatex);
        setCanvasFlatTree(flattenTreeToLeaves(expressionalLatex));
    }, []);

    useLayoutEffect(() => {
        /*
            when canvas changes we grab it and update a lines state
            this lines state is like the canvas but split into lines
            inside this useLayoutEffect we will create these lines
            we will directly use the dom
            1. get dom canvas
            2. iterate over children
            3. when the accumulated width > 500px we stop, push the line
            4. repeat with a new clean line object for the other children

            ASSUMPTIONS:

            1. every 1 DOM object is analogous to 1 surface level canvas object

            PREREQUISITE SYSTEM:

            1. need a way to identify exactly which canvas you're in, for now we can assume its the only one for demonstration purposes
        */

        const newCalculatedLineWidth = lineWidth?.includes("%") ? Math.round(parseFloat(document.getElementById(id + "-text-editor-container").parentNode.offsetWidth) * (parseFloat(lineWidth) / 100)) : lineWidth;

        calculatedLineWidth.current = newCalculatedLineWidth;

        let accumulatedWidth = 0;
        let currentLine = [];
        let allLines = {
            lineBreaks: [],
            nodeObject: {
                children: [

                ]
            }
        };

        if (!canvas?.nodeObject) return;

        const DOMCanvas = document.getElementById(id + "-hidden-div");
        const childrenArray = Array.from(DOMCanvas.children);
        const filteredChildren = childrenArray.filter(item => !item.id.includes('cursor'));

        function createNewCanvas() {
            return {
                nodeObject: {
                    children: []
                }
            };
        };

        let newCanvas = createNewCanvas();
        let totalIndex = 0;

        Array.from(filteredChildren).forEach((child, index) => {
            totalIndex++;
            const childWidth = child.offsetWidth;
            accumulatedWidth += childWidth;

            if (accumulatedWidth > calculatedLineWidth.current) {
                // Add current line to newCanvas and push to allLines
                newCanvas.nodeObject.children = currentLine;
                allLines.nodeObject.children.push(newCanvas);
                // Reset for new line
                newCanvas = createNewCanvas();
                currentLine = [];
                accumulatedWidth = childWidth;
            };

            const functionName = child.getAttribute('functionname');
            if (functionName === 'newline') {
                accumulatedWidth = Infinity;
            };

            if (currentLine.length === 0) {
                allLines.lineBreaks.push({
                    line: allLines.nodeObject.children.length + 1,
                    startIndex: index,
                    endIndex: index,
                    total: index,
                })
            };

            allLines.lineBreaks[allLines.lineBreaks.length - 1].endIndex = index;
            allLines.lineBreaks[allLines.lineBreaks.length - 1].total = totalIndex - (index - allLines.lineBreaks[allLines.lineBreaks.length - 1].startIndex);

            currentLine.push(canvas?.nodeObject?.children[index]);
        });

        // Handle the last line
        if (currentLine.length > 0) {
            newCanvas.nodeObject.children = currentLine;
            allLines.nodeObject.children.push(newCanvas);
        };

        setCanvasByLines(allLines);
    }, [canvas])

    const handleKeyDown = (e) => {
        const { key, altKey, shiftKey, ctrlKey, metaKey } = e.nativeEvent ? e.nativeEvent : e;

        if (e.preventDefault) {
            if (key === "r" && metaKey) return;
            if (key === "+" && (ctrlKey || metaKey)) return;
            if (key === "=" && (ctrlKey || metaKey)) return;
            if (key === "-" && (ctrlKey || metaKey)) return;
            if (key === "_" && (ctrlKey || metaKey)) return;
            if (key === 'Escape') return;
            e.preventDefault();
        };

        const bannedInputs = [
            ",",
            "+",
            "-",
            "=",
            " ",
            "\r", // Carriage Return
            "\n", // Line Feed
        ];

        let newNodePath = [...nodePath];
        let newCanvasIndex = canvasIndex;
        let newNodeId = nodeId;

        let grandParent = getNodeAtPath(canvas, nodePath.slice(0, -2));
        let parent = getNodeAtPath(canvas, nodePath.slice(0, -1));
        let auxNode = getNodeAtPath(canvas, nodePath);

        const canvasCopy = {
            nodeObject: {
                children: canvas.nodeObject.children.filter(item => item.nodeObject.type !== "newline"),
            }
        };

        const flatTree = flattenTreeToLeaves(canvas).filter(item => item.nodeObject?.type !== "expression");
        const flatIndex = flatTree.findIndex(item => arraysAreEqual(item.nodeObject.path, nodePath));

        if (key === "ArrowLeft") {
            if (!selecting && selection.length > 0 && !metaKey && !shiftKey && !altKey) {
                newCanvasIndex = selectionTails.current[0].realIndex;
                newNodePath = selection[0].nodeObject.path;
                newNodeId = `${id}-${selection[0].nodeObject.startIndex}-${selection[0].nodeObject.endIndex}`;

                epicenterSelect.current = {
                    // null values as these will be determined by mouseMove in the next mouse movement
                    eNodePath: nodePath,
                    eNode: getNodeAtPath(canvas, newNodePath),
                    eCanvasIndex: newCanvasIndex,
                    eTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                    oneTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                    verticalDirection: null,
                    horizontalDirection: "left",
                    x1: e.clientX,
                    y1: e.clientY,
                    x2: null,
                    y2: null,
                    eNodeCount: nodeId,
                    startTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                    endTailIndex: null,
                };


                if (selectionTails.current) {
                    selectionTails.current[0].realIndex = 0;
                    selectionTails.current[1].realIndex = 0;
                };

                setSelection([]);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setPrevNodePath(newNodePath);
                setCanvasIndex(newCanvasIndex);
                return;
            };

            if (!epicenterSelect.current) {
                epicenterSelect.current = {
                    horizontalDirection: "left",
                };
            }

            if (metaKey) {
                const surfaceIndex = nodePath[0];
                const lineIndex = canvasByLines.lineBreaks.findIndex(item => surfaceIndex >= item.startIndex && surfaceIndex <= item.endIndex)
                const line = canvasByLines.lineBreaks[lineIndex];

                const firstItemOfLine = getNodeAtPath(canvas, [line.startIndex]);

                newCanvasIndex = firstItemOfLine.startIndex;
                newNodePath = firstItemOfLine.path;
                newNodeId = `${id}-${firstItemOfLine.startIndex}-${firstItemOfLine.endIndex}`;

                if (shiftKey) {
                    const firstLineItemFlatIndex = flatTree.findIndex(item => startsWithArray(item.nodeObject.path, firstItemOfLine.path));

                    const range = canvasFlatTree.slice(firstLineItemFlatIndex, flatIndex + 1);
                    const shortestPathLength = Math.min(...range.map(item => item.nodeObject.path.length));
                    const better = refineAndDeduplicatePaths(range, canvas, shortestPathLength);

                    setSelection(better);

                    const startTailIndex = 0;
                    const endTailIndex = canvasIndex - getNodeAtPath(canvas, nodePath).startIndex;

                    if (better.length === 1 && startTailIndex === endTailIndex) {
                        if (selectionTails.current) {
                            selectionTails.current[0].realIndex = 0;
                            selectionTails.current[1].realIndex = 0;
                        };

                        setSelection([]);
                    };

                    selectionTails.current = [{ nodeCount: newNodeId, startIndex: startTailIndex, realIndex: better[0].nodeObject.startIndex }, { nodeCount: nodeId, endIndex: endTailIndex, realIndex: canvasIndex }];
                    return;
                };

                setPrevNodePath(newNodePath);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvasIndex(newCanvasIndex);
                return;
            };

            if (altKey) {
                if (selection.length > 0) {
                    if (!epicenterSelect.current) {
                        const domTarget = document.getElementById(id + "-canvas-input-cursor");
                        const targetRect = domTarget.getBoundingClientRect();

                        const e = { clientX: targetRect.x, clientY: targetRect.y }
                        const clickData = handleClick(e);

                        if (selectionTails.current) {
                            selectionTails.current[0].realIndex = 0;
                            selectionTails.current[1].realIndex = 0;
                        };

                        setSelection([]);

                        if (!clickData) return;

                        const { newNodeCount, newCanvasIndex, newNodePath } = clickData;

                        epicenterSelect.current = {
                            // null values as these will be determined by mouseMove in the next mouse movement
                            eNodePath: nodePath,
                            eNode: getNodeAtPath(canvas, newNodePath),
                            eCanvasIndex: newCanvasIndex,
                            eTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                            oneTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                            verticalDirection: null,
                            horizontalDirection: "left",
                            x1: e.clientX,
                            y1: e.clientY,
                            x2: null,
                            y2: null,
                            eNodeCount: newNodeCount,
                            startTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                            endTailIndex: null,
                        };

                    };

                    const { eCanvasIndex, eTailIndex, eNodeCount } = epicenterSelect.current;

                    const direction = epicenterSelect.current.horizontalDirection;

                    const first = selection[0].nodeObject;
                    const last = selection[selection.length - 1].nodeObject;

                    let eIndex = findIndexReverse(flatTree, eCanvasIndex);
                    let index = findIndexReverse(flatTree, eCanvasIndex === selectionTails.current[0].realIndex ? selectionTails.current[1].realIndex : selectionTails.current[0].realIndex);

                    if (direction === "left") {
                        eIndex = findIndex(flatTree, eCanvasIndex);
                        index = findIndex(flatTree, eCanvasIndex === selectionTails.current[0].realIndex ? selectionTails.current[1].realIndex : selectionTails.current[0].realIndex);
                    };

                    let startIndex = Math.min(eIndex, index);
                    let endIndex = Math.max(eIndex, index);

                    if (selectionTails.current[0].realIndex === 0) return;

                    if (direction === "right") {
                        endIndex--;
                    } else if (direction === "left") {
                        startIndex--;
                    };

                    let range = flatTree.slice(startIndex, endIndex + 1);
                    let shortestPathLength = Math.min(...range.map(item => item.nodeObject.path.length));
                    let better = refineAndDeduplicatePaths(range, canvas, shortestPathLength);

                    if (better.length === 0) {
                        if (direction === "right") {
                            selectionTails.current[1].realIndex = selectionTails.current[0].realIndex;
                        } else if (direction === "left") {
                            selectionTails.current[0].realIndex = selectionTails.current[1].realIndex;
                        };
                    } else if (direction === "right") {
                        // selectionTails.current[0].realIndex = better[0].nodeObject.startIndex;
                        selectionTails.current[1].realIndex = better[better.length - 1].nodeObject.endIndex;
                    } else if (direction === "left") {
                        selectionTails.current[0].realIndex = better[0].nodeObject.startIndex;
                        // selectionTails.current[1].realIndex = better[better.length - 1].nodeObject.endIndex;
                    };


                    setSelection(better);
                } else {
                    if (epicenterSelect.current) {
                        epicenterSelect.current.horizontalDirection = "left";
                    } else {
                        epicenterSelect.current = {
                            horizontalDirection: "left",
                        };
                    };

                    const index = findIndexReverse(canvasFlatTree, newCanvasIndex);
                    const leaf = canvasFlatTree[index];

                    const nodeCount = `${id}-${leaf.nodeObject.startIndex}-${leaf.nodeObject.endIndex}`;

                    const tail1 = { nodeCount: nodeCount, startIndex: newCanvasIndex - leaf.nodeObject.latexText.length, realIndex: leaf.nodeObject.startIndex };
                    const tail2 = { nodeCount: nodeCount, endIndex: leaf.nodeObject.latexText.length, startIndex: leaf.nodeObject.latexText.length, realIndex: newCanvasIndex };

                    selectionTails.current = [tail1, tail2];
                    setSelection([leaf]);
                };

                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setPrevNodePath(newNodePath);
                setCanvasIndex(newCanvasIndex);

                return;
            };

            if (shiftKey) {
                if (selection.length > 0) {
                    const { eCanvasIndex, eTailIndex, eNodeCount } = epicenterSelect.current;
                    const direction = epicenterSelect.current.horizontalDirection;

                    if (selectionTails.current[0].realIndex === 0) return;

                    if (direction === "right") {
                        selectionTails.current[1].realIndex--;
                    } else if (direction === "left") {
                        selectionTails.current[0].realIndex--;
                    };

                    let eIndex = findIndexReverse(flatTree, selectionTails.current[0].realIndex);
                    let index = findIndexReverse(flatTree, selectionTails.current[1].realIndex);

                    // if (direction === "left") {
                    //     eIndex = findIndex(flatTree, eCanvasIndex);
                    //     index = findIndex(flatTree, selectionTails.current[1].realIndex);
                    // };

                    const startIndex = Math.min(eIndex, index);
                    const endIndex = Math.max(eIndex, index);

                    const range = flatTree.slice(startIndex, endIndex + 1);
                    const shortestPathLength = Math.min(...range.map(item => item.nodeObject.path.length));
                    const better = refineAndDeduplicatePaths(range, canvas, shortestPathLength);

                    if (better[0].nodeObject.latexType !== "text" && !better[0].nodeObject.latexType.includes("_placeholder")) {
                        selectionTails.current[0].realIndex = better[0].nodeObject.startIndex;
                    };

                    if (better[better.length - 1].nodeObject.latexType !== "text" && !better[better.length - 1].nodeObject.latexType.includes("_placeholder")) {
                        if (selectionTails.current[1].realIndex + 1 === better[better.length - 1].nodeObject.endIndex) {
                            selectionTails.current[1].realIndex = better[better.length - 1].nodeObject.startIndex;
                        } else {
                            selectionTails.current[1].realIndex = better[better.length - 1].nodeObject.endIndex;
                        };
                    };

                    if (selectionTails.current[0].realIndex === selectionTails.current[1].realIndex) {
                        if (selectionTails.current) {
                            selectionTails.current[0].realIndex = 0;
                            selectionTails.current[1].realIndex = 0;
                        };

                        setSelection([]);
                    } else {
                        setSelection(better);
                    };
                } else {
                    console.log('here');
                    if (epicenterSelect.current) {
                        epicenterSelect.current.horizontalDirection = "left";
                    } else {
                        epicenterSelect.current = {
                            horizontalDirection: "left",
                        };
                    };

                    const index = findIndexReverse(canvasFlatTree, newCanvasIndex);
                    const leaf = canvasFlatTree[index];

                    const nodeCount = `${id}-${leaf.nodeObject.startIndex}-${leaf.nodeObject.endIndex}`;

                    const tail1 = { nodeCount: nodeCount, startIndex: newCanvasIndex - leaf.nodeObject.latexText.length, realIndex: newCanvasIndex - 1 };
                    const tail2 = { nodeCount: nodeCount, endIndex: leaf.nodeObject.latexText.length, startIndex: leaf.nodeObject.latexText.length, realIndex: newCanvasIndex };

                    selectionTails.current = [tail1, tail2];
                    setSelection([leaf]);
                };

                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setPrevNodePath(newNodePath);
                setCanvasIndex(newCanvasIndex);

                return;
            };

            if (newCanvasIndex <= 0 && flatIndex <= 0) return;

            newCanvasIndex--;

            if (newCanvasIndex < auxNode?.startIndex) {
                const newFlatTree = flatTree.filter(item => (getNodeAtPath(canvas, item.nodeObject.path.slice(0, -2))?.type !== "newline"));
                const index = findIndex(newFlatTree, newCanvasIndex);
                const nodeA = newFlatTree[index].nodeObject

                console.log(index);

                newNodePath = nodeA.path;
                newCanvasIndex = nodeA.endIndex;
                newNodeId = `${id}-${nodeA.startIndex}-${nodeA.endIndex}`;

                if (newCanvasIndex === auxNode?.startIndex) {
                    newCanvasIndex--;
                }
            };

            setNodePath(newNodePath);
            setNodeId(newNodeId);
            setPrevNodePath(newNodePath);
            setCanvasIndex(newCanvasIndex);
        }
        else if (key === "ArrowRight") {
            if (!selecting && selection.length > 0 && !metaKey && !shiftKey && !altKey) {
                newCanvasIndex = selectionTails.current[1].realIndex;
                newNodePath = selection[selection.length - 1].nodeObject.path;
                newNodeId = `${id}-${selection[selection.length - 1].nodeObject.startIndex}-${selection[selection.length - 1].nodeObject.endIndex}`;

                epicenterSelect.current = {
                    // null values as these will be determined by mouseMove in the next mouse movement
                    eNodePath: nodePath,
                    eNode: getNodeAtPath(canvas, newNodePath),
                    eCanvasIndex: newCanvasIndex,
                    eTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                    oneTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                    verticalDirection: null,
                    horizontalDirection: "left",
                    x1: e.clientX,
                    y1: e.clientY,
                    x2: null,
                    y2: null,
                    eNodeCount: nodeId,
                    startTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                    endTailIndex: null,
                };

                if (selectionTails.current) {
                    selectionTails.current[0].realIndex = 0;
                    selectionTails.current[1].realIndex = 0;
                };

                setSelection([]);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setPrevNodePath(newNodePath);
                setCanvasIndex(newCanvasIndex);
                return;
            };

            if (!epicenterSelect.current) {
                epicenterSelect.current = {
                    horizontalDirection: "right",
                };
            };

            if (metaKey) {
                // reverse logic of command left

                const surfaceIndex = nodePath[0];
                const lineIndex = canvasByLines.lineBreaks.findIndex(item => surfaceIndex >= item.startIndex && surfaceIndex <= item.endIndex)
                const line = canvasByLines.lineBreaks[lineIndex];

                const lastItemOfLine = getNodeAtPath(canvas, [line.endIndex]);

                newCanvasIndex = lastItemOfLine.endIndex;
                newNodePath = lastItemOfLine.path;
                newNodeId = `${id}-${lastItemOfLine.startIndex}-${lastItemOfLine.endIndex}`;

                if (shiftKey) {
                    const lastItemLineFlatIndex = flatTree.findIndex(item => startsWithArray(item.nodeObject.path, lastItemOfLine.path));

                    const range = canvasFlatTree.slice(flatIndex, lastItemLineFlatIndex + 1);
                    const shortestPathLength = Math.min(...range.map(item => item.nodeObject.path.length));
                    const better = refineAndDeduplicatePaths(range, canvas, shortestPathLength);

                    setSelection(better);

                    const startTailIndex = canvasIndex - getNodeAtPath(canvas, nodePath).startIndex;
                    const endTailIndex = lastItemOfLine.latexText.length;


                    if (better.length === 1 && startTailIndex === endTailIndex) { // for when at start / end of line to prevent cursor from disappearing
                        if (selectionTails.current) {
                            selectionTails.current[0].realIndex = 0;
                            selectionTails.current[1].realIndex = 0;
                        };

                        setSelection([]);
                    };

                    selectionTails.current = [{ nodeCount: nodeId, startIndex: startTailIndex, realIndex: canvasIndex }, { nodeCount: newNodeId, endIndex: endTailIndex, realIndex: better[better.length - 1].nodeObject.endIndex }];

                    return;
                };

                setPrevNodePath(newNodePath);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvasIndex(newCanvasIndex);
                return;
            };

            if (altKey) {
                if (selection.length > 0) {
                    if (!epicenterSelect.current) {
                        const domTarget = document.getElementById(id + "-canvas-input-cursor");
                        const targetRect = domTarget.getBoundingClientRect();

                        const e = { clientX: targetRect.x, clientY: targetRect.y }
                        const clickData = handleClick(e);

                        if (selectionTails.current) {
                            selectionTails.current[0].realIndex = 0;
                            selectionTails.current[1].realIndex = 0;
                        };

                        setSelection([]);

                        if (!clickData) return;

                        const { newNodeCount, newCanvasIndex, newNodePath } = clickData;

                        epicenterSelect.current = {
                            // null values as these will be determined by mouseMove in the next mouse movement
                            eNodePath: nodePath,
                            eNode: getNodeAtPath(canvas, newNodePath),
                            eCanvasIndex: newCanvasIndex,
                            eTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                            oneTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                            verticalDirection: null,
                            horizontalDirection: "right",
                            x1: e.clientX,
                            y1: e.clientY,
                            x2: null,
                            y2: null,
                            eNodeCount: newNodeCount,
                            startTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                            endTailIndex: null,
                        };

                    };

                    const { eCanvasIndex, eTailIndex, eNodeCount } = epicenterSelect.current;

                    const direction = epicenterSelect.current.horizontalDirection;

                    let eIndex = findIndexReverse(flatTree, eCanvasIndex);
                    let index = findIndexReverse(flatTree, eCanvasIndex === selectionTails.current[0].realIndex ? selectionTails.current[1].realIndex : selectionTails.current[0].realIndex);

                    if (direction === "left") {
                        eIndex = findIndex(flatTree, eCanvasIndex);
                        index = findIndex(flatTree, eCanvasIndex === selectionTails.current[0].realIndex ? selectionTails.current[1].realIndex : selectionTails.current[0].realIndex);
                    };

                    let startIndex = Math.min(eIndex, index);
                    let endIndex = Math.max(eIndex, index);

                    if (direction === "right") {
                        endIndex++;
                        console.log('here');
                    } else if (direction === "left") {
                        startIndex++;
                    };

                    let range = flatTree.slice(startIndex, endIndex + 1);
                    let shortestPathLength = Math.min(...range.map(item => item.nodeObject.path.length));
                    let better = refineAndDeduplicatePaths(range, canvas, shortestPathLength);

                    if (better.length === 0) {
                        if (direction === "right") {
                            selectionTails.current[1].realIndex = selectionTails.current[0].realIndex;
                        } else if (direction === "left") {
                            selectionTails.current[0].realIndex = selectionTails.current[1].realIndex;
                        };
                    } else if (direction === "right") {
                        // selectionTails.current[0].realIndex = better[0].nodeObject.startIndex;
                        selectionTails.current[1].realIndex = better[better.length - 1].nodeObject.endIndex;
                    } else if (direction === "left") {
                        selectionTails.current[0].realIndex = better[0].nodeObject.startIndex;
                        // selectionTails.current[1].realIndex = better[better.length - 1].nodeObject.endIndex;
                    };

                    setSelection(better);
                } else {
                    if (epicenterSelect.current) {
                        epicenterSelect.current.horizontalDirection = "right";
                    } else {
                        epicenterSelect.current = {
                            horizontalDirection: "right",
                        };
                    };

                    const index = findIndex(canvasFlatTree, newCanvasIndex);
                    const leaf = canvasFlatTree[index];

                    const nodeCount = `${id}-${leaf.nodeObject.startIndex}-${leaf.nodeObject.endIndex}`;

                    const tail1 = { nodeCount: nodeCount, startIndex: leaf.nodeObject.endIndex - newCanvasIndex, realIndex: newCanvasIndex };
                    const tail2 = { nodeCount: nodeCount, endIndex: 0, startIndex: 0, realIndex: leaf.nodeObject.endIndex };

                    selectionTails.current = [tail1, tail2];
                    setSelection([leaf]);
                };

                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setPrevNodePath(newNodePath);
                setCanvasIndex(newCanvasIndex);

                return;
            };

            if (shiftKey) {
                if (selection.length > 0) {
                    const { eCanvasIndex, eTailIndex, eNodeCount } = epicenterSelect.current;

                    const direction = epicenterSelect.current.horizontalDirection;

                    if (selectionTails.current[1].realIndex === questionCanvas.current.length) return;

                    if (direction === "right") {
                        selectionTails.current[1].realIndex++;
                    } else if (direction === "left") {
                        selectionTails.current[0].realIndex++;
                    };

                    let eIndex = findIndexReverse(flatTree, selectionTails.current[0].realIndex);
                    let index = findIndexReverse(flatTree, selectionTails.current[1].realIndex);

                    const startIndex = Math.min(eIndex, index);
                    const endIndex = Math.max(eIndex, index);

                    const range = flatTree.slice(startIndex, endIndex + 1);
                    const shortestPathLength = Math.min(...range.map(item => item.nodeObject.path.length));
                    const better = refineAndDeduplicatePaths(range, canvas, shortestPathLength);


                    if (better[0].nodeObject.latexType !== "text" && !better[0].nodeObject.latexType.includes("_placeholder")) {
                        console.log(selectionTails.current[0].realIndex, better[0].nodeObject.startIndex)
                        if (selectionTails.current[0].realIndex - 1 === better[0].nodeObject.startIndex) {
                            console.log('here2')
                            selectionTails.current[0].realIndex = better[0].nodeObject.endIndex;
                        } else {
                            selectionTails.current[0].realIndex = better[0].nodeObject.startIndex;
                        };
                    };

                    if (better[better.length - 1].nodeObject.latexType !== "text" && !better[better.length - 1].nodeObject.latexType.includes("_placeholder")) {
                        console.log(better.length, selectionTails.current[1].realIndex, better[better.length - 1].nodeObject.endIndex);
                        selectionTails.current[1].realIndex = better[better.length - 1].nodeObject.endIndex;
                    };

                    if (selectionTails.current[0].realIndex === selectionTails.current[1].realIndex) {
                        if (selectionTails.current) {
                            selectionTails.current[0].realIndex = 0;
                            selectionTails.current[1].realIndex = 0;
                        };

                        setSelection([]);
                    } else {
                        setSelection(better);
                    };
                } else {
                    epicenterSelect.current.horizontalDirection = "right";

                    const index = findIndexReverse(canvasFlatTree, newCanvasIndex);
                    let leaf = canvasFlatTree[index];

                    if (newCanvasIndex === leaf.nodeObject.endIndex) {
                        leaf = canvasFlatTree[index + 1];
                        const leafGrandParent = getNodeAtPath(canvas, leaf.nodeObject.path.slice(0, -2));
                        if (leafGrandParent.type !== "text" && leafGrandParent.type !== "expression") {
                            leaf = { nodeObject: leafGrandParent };
                        };
                    };

                    const nodeCount = `${id}-${leaf.nodeObject.startIndex}-${leaf.nodeObject.endIndex}`;

                    let tail1 = { nodeCount: nodeCount, startIndex: newCanvasIndex - leaf.nodeObject.latexText.length, realIndex: newCanvasIndex };
                    let tail2 = { nodeCount: nodeCount, endIndex: leaf.nodeObject.latexText.length, startIndex: leaf.nodeObject.latexText.length, realIndex: newCanvasIndex + 1 };

                    if ((leaf.nodeObject.latexType !== "text" && !leaf.nodeObject.latexType.includes("_placeholder"))) {
                        tail1.realIndex = leaf.nodeObject.startIndex;
                        tail2.realIndex = leaf.nodeObject.endIndex;
                    };

                    selectionTails.current = [tail1, tail2];
                    setSelection([leaf]);
                };

                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setPrevNodePath(newNodePath);
                setCanvasIndex(newCanvasIndex);

                return;
            };

            if ((!auxNode || newCanvasIndex === auxNode?.endIndex) && flatIndex === flatTree.length - 1) return;

            newCanvasIndex++;

            if (newCanvasIndex > auxNode.endIndex) {
                const newFlatTree = flatTree.filter(item => (getNodeAtPath(canvas, item.nodeObject.path.slice(0, -2))?.type !== "newline"));
                const index = findIndexReverse(newFlatTree, newCanvasIndex);
                const nodeA = newFlatTree[index].nodeObject

                newNodePath = nodeA.path;
                newCanvasIndex = nodeA.startIndex;
                newNodeId = `${id}-${nodeA.startIndex}-${nodeA.endIndex}`;

                if (newCanvasIndex === canvasIndex) {
                    newCanvasIndex++;
                };
            };

            setNodePath(newNodePath);
            setNodeId(newNodeId);
            setPrevNodePath(newNodePath);
            setCanvasIndex(newCanvasIndex);
        }

        else if (key === "ArrowDown" && !shiftKey && !metaKey && !ctrlKey && !altKey) {
            const surfaceIndex = nodePath[0];
            const lineIndex = canvasByLines.lineBreaks.findIndex(item => surfaceIndex >= item.startIndex && surfaceIndex <= item.endIndex);

            let pool;

            let gPUniversal = 0;
            let gPMax = 0;

            newNodePath.forEach((num, index) => index % 2 === 0 && index !== 0 && (gPUniversal += newNodePath[index - 1]));
            newNodePath.forEach((num, index) => index % 2 === 0 && index !== 0 && (gPMax += getNodeAtPath(canvas, newNodePath.slice(0, index - 1)).children.length - 1));

            if (gPUniversal === gPMax) {
                // get line below
                if (lineIndex + 1 > canvasByLines.nodeObject.children.length - 1) {
                    console.log("no lines below");
                    return;
                }

                const nextLine = canvasByLines.lineBreaks[lineIndex + 1];

                pool = flattenTreeToShallowestLeaves({ nodeObject: { children: canvas.nodeObject.children.slice(nextLine.startIndex, nextLine.endIndex + 1) } }, [], nextLine.startIndex);
            } else {
                // other cases
                let poolPath = newNodePath.slice(0, -1);
                let node = getNodeAtPath(canvas, poolPath.slice(0, -1));
                // Check if the last element equals the number of children at the node - 1 (last index)
                while (poolPath.length > 0 && poolPath[poolPath.length - 1] === node.children.length - 1) {
                    poolPath.pop(); // Remove the last element because it's at max
                    if (poolPath.length > 0) {
                        poolPath.pop(); // Step back to the parent node in the path
                        node = getNodeAtPath(canvas, poolPath.slice(0, -1)); // Update node to new parent node
                    }
                }

                // If there are still elements left in the array, increment the last one
                if (poolPath.length > 0) {
                    poolPath[poolPath.length - 1]++;
                } else {
                    // Handle the case where poolPath is empty after popping elements
                    // This could involve resetting poolPath to a valid starting point or handling an underflow condition
                }

                pool = flattenTreeToShallowestLeaves({ nodeObject: getNodeAtPath(canvas, poolPath) }, poolPath);
            };

            const domTarget = document.getElementById(id + "-canvas-input-cursor");

            let closestDomChild = null;
            let closestDistance = Infinity;
            let closestNodeCount = `${id}-${pool[0].nodeObject.startIndex}-${pool[0].nodeObject.endIndex}`;
            let closestNodeIndex = 0;
            let closestDirection;

            pool.forEach((child, index) => {
                const nodeCount = `${id}-${child.nodeObject.startIndex}-${child.nodeObject.endIndex}`;
                const domChild = document.querySelector(`[nodeCount="${nodeCount}"]`);

                if (domChild) {
                    const rectTarget = domTarget.getBoundingClientRect();
                    const charRects = getCharacterRects(domChild); // Assume getCharacterRects is defined

                    if (charRects.length === 0) {
                        // No character rects, so compare domChild's bounding rect to the target
                        const rectChild = domChild.getBoundingClientRect();
                        const distances = [
                            Math.abs(rectTarget.left - rectChild.left),
                            Math.abs(rectTarget.right - rectChild.right),
                            Math.abs((rectTarget.left + rectTarget.right) / 2 - (rectChild.left + rectChild.right) / 2)
                        ];

                        const minDistance = Math.min(...distances);

                        if (minDistance < closestDistance) {
                            closestDistance = minDistance;
                            closestDomChild = domChild;
                            closestNodeCount = nodeCount;
                            closestNodeIndex = -1; // No char rects, indicating a fallback to element-level comparison

                            // Determine direction based on comparison of the center points
                            closestDirection = (rectChild.left + rectChild.right) / 2 < (rectTarget.left + rectTarget.right) / 2 ? 'left' : 'right';
                        }
                    } else {
                        // Process character rects
                        charRects.forEach((rectChild, charIndex) => {
                            const distances = [
                                Math.abs(rectTarget.left - rectChild.left),
                                Math.abs(rectTarget.right - rectChild.right),
                                Math.abs((rectTarget.left + rectTarget.right) / 2 - (rectChild.left + rectChild.right) / 2)
                            ];

                            const minDistance = Math.min(...distances);

                            if (minDistance < closestDistance) {
                                closestDistance = minDistance;
                                closestDomChild = domChild;
                                closestNodeCount = nodeCount;
                                closestNodeIndex = charIndex;

                                // Determine direction based on the comparison of the center points
                                closestDirection = (rectChild.left + rectChild.right) / 2 < (rectTarget.left + rectTarget.right) / 2 ? 'left' : 'right';
                            }
                        });
                    }
                }
            });

            const index = flatTree.findIndex(item => `${id}-${item.nodeObject.startIndex}-${item.nodeObject.endIndex}` === closestNodeCount);
            const leaf = flatTree[index].nodeObject;

            newNodePath = leaf.path;
            newCanvasIndex = leaf.startIndex + closestNodeIndex + (closestDirection === "left" ? 1 : 0);
            newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

            if (leaf.latexType === "placeholder") {
                newCanvasIndex = leaf.startIndex;
            };

            epicenterSelect.current = {
                // null values as these will be determined by mouseMove in the next mouse movement
                eNodePath: nodePath,
                eNode: getNodeAtPath(canvas, newNodePath),
                eCanvasIndex: newCanvasIndex,
                eTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                oneTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                verticalDirection: null,
                horizontalDirection: null,
                x1: e.clientX,
                y1: e.clientY,
                x2: null,
                y2: null,
                eNodeCount: newNodeId,
                startTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                endTailIndex: null,
            };

            if (leaf.latexType === "placeholder") {
                const gP = getNodeAtPath(canvas, leaf.path.slice(0, -2));

                if (gP.type === "newline") {
                    newNodePath = gP.path;
                    newCanvasIndex = gP.startIndex;
                    newNodeId = `${id}-${gP.startIndex}-${gP.endIndex}`;
                };
            };

            setPrevNodePath(newNodePath);
            setNodePath(newNodePath);
            setNodeId(newNodeId);
            setCanvasIndex(newCanvasIndex);
            return;
        }
        else if (key === "ArrowUp" && !shiftKey && !metaKey && !ctrlKey && !altKey) {
            const surfaceIndex = nodePath[0];
            const lineIndex = canvasByLines.lineBreaks.findIndex(item => surfaceIndex >= item.startIndex && surfaceIndex <= item.endIndex);

            let pool;

            let gPUniversal = 0;

            newNodePath.forEach((num, index) => index % 2 === 0 && index !== 0 && (gPUniversal += newNodePath[index - 1]));

            if (gPUniversal === 0) {
                // get line above
                if (lineIndex - 1 < 0) {
                    console.log("no lines above");
                    return;
                }

                const previousLine = canvasByLines.lineBreaks[lineIndex - 1];
                pool = flattenTreeToDeepestLeaves({ nodeObject: { children: canvas.nodeObject.children.slice(previousLine.startIndex, previousLine.endIndex + 1) } }, [], previousLine.startIndex);
            } else {
                // other cases
                let poolPath = newNodePath.slice(0, -1);

                // Check if the last element is 0
                while (poolPath.length > 0 && poolPath[poolPath.length - 1] === 0) {
                    poolPath.pop(); // Remove the last element if it is 0
                    if (poolPath.length > 0) { // Check to prevent accessing negative index
                        poolPath.pop(); // Remove the last element again to step back in the path
                    }
                };

                // If there are still elements left in the array, decrement the last one
                if (poolPath.length > 0) {
                    poolPath[poolPath.length - 1]--;
                };

                pool = flattenTreeToDeepestLeaves({ nodeObject: getNodeAtPath(canvas, poolPath) }, poolPath);
            };

            const domTarget = document.getElementById(id + "-canvas-input-cursor");

            let closestDomChild = null;
            let closestDistance = Infinity;
            let closestNodeCount = `${id}-${pool[0].nodeObject.startIndex}-${pool[0].nodeObject.endIndex}`;
            let closestNodeIndex = 0;
            let closestDirection;

            let adj = 0;

            pool.forEach((child, index) => {
                const nodeCount = `${id}-${child.nodeObject.startIndex}-${child.nodeObject.endIndex}`;
                const domChild = document.querySelector(`[nodeCount="${nodeCount}"]`);


                if (domChild) {
                    let rectTarget;

                    if (selection.length > 0) { // temp solution
                        console.log(selection[0].nodeObject);

                        const targetNodeCount = `${id}-${selection[0].nodeObject.startIndex}-${selection[0].nodeObject.endIndex}`
                        // always left like google docs change later for horizontal direction

                        const newDomTarget = document.querySelector(`[nodeCount="${targetNodeCount}"]`);

                        const newDomTargetDimensions = newDomTarget.getBoundingClientRect();

                        if (selection[0].nodeObject.type !== "text") {
                            adj = selectionTails.current[0].startIndex;
                        };
                        rectTarget = { left: newDomTargetDimensions.left, right: newDomTargetDimensions.left };
                    } else {
                        rectTarget = domTarget.getBoundingClientRect()
                    };

                    const charRects = getCharacterRects(domChild); // Assume getCharacterRects is defined

                    if (charRects.length === 0) {
                        // No character rects, so compare domChild's bounding rect to the target
                        const rectChild = domChild.getBoundingClientRect();
                        const distances = [
                            Math.abs(rectTarget.left - rectChild.left),
                            Math.abs(rectTarget.right - rectChild.right),
                            Math.abs((rectTarget.left + rectTarget.right) / 2 - (rectChild.left + rectChild.right) / 2)
                        ];

                        const minDistance = Math.min(...distances);

                        if (minDistance < closestDistance) {
                            closestDistance = minDistance;
                            closestDomChild = domChild;
                            closestNodeCount = nodeCount;
                            closestNodeIndex = -1; // No char rects, indicating a fallback to element-level comparison

                            // Determine direction based on comparison of the center points
                            closestDirection = (rectChild.left + rectChild.right) / 2 < (rectTarget.left + rectTarget.right) / 2 ? 'left' : 'right';
                        }
                    } else {
                        // Process character rects
                        charRects.forEach((rectChild, charIndex) => {
                            const distances = [
                                Math.abs(rectTarget.left - rectChild.left),
                                Math.abs(rectTarget.right - rectChild.right),
                                Math.abs((rectTarget.left + rectTarget.right) / 2 - (rectChild.left + rectChild.right) / 2)
                            ];

                            const minDistance = Math.min(...distances);

                            if (minDistance < closestDistance) {
                                closestDistance = minDistance;
                                closestDomChild = domChild;
                                closestNodeCount = nodeCount;
                                closestNodeIndex = charIndex;

                                // Determine direction based on the comparison of the center points
                                closestDirection = (rectChild.left + rectChild.right) / 2 < (rectTarget.left + rectTarget.right) / 2 ? 'left' : 'right';
                            }
                        });
                    }
                }
            });

            const index = flatTree.findIndex(item => `${id}-${item.nodeObject.startIndex}-${item.nodeObject.endIndex}` === closestNodeCount);
            const leaf = flatTree[index].nodeObject;

            newNodePath = leaf.path;
            newCanvasIndex = leaf.startIndex + closestNodeIndex + (closestDirection === "left" ? 1 : 0) + adj;
            newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

            if (leaf.latexType === "placeholder") {
                newCanvasIndex = leaf.startIndex;
            };

            epicenterSelect.current = {
                // null values as these will be determined by mouseMove in the next mouse movement
                eNodePath: nodePath,
                eNode: getNodeAtPath(canvas, newNodePath),
                eCanvasIndex: newCanvasIndex,
                eTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                oneTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                verticalDirection: null,
                horizontalDirection: null,
                x1: e.clientX,
                y1: e.clientY,
                x2: null,
                y2: null,
                eNodeCount: newNodeId,
                startTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
                endTailIndex: null,
            };

            // again this newline guy is making everyone change awful

            if (leaf.latexType === "placeholder") {
                const gP = getNodeAtPath(canvas, leaf.path.slice(0, -2));

                if (gP.type === "newline") {
                    newNodePath = gP.path;
                    newCanvasIndex = gP.startIndex;
                    newNodeId = `${id}-${gP.startIndex}-${gP.endIndex}`;
                };
            };

            setPrevNodePath(newNodePath);
            setNodePath(newNodePath);
            setNodeId(newNodeId);
            setCanvasIndex(newCanvasIndex);
            return;
        }
        else if (key === "Backspace") {
            questionCanvas.current = questionCanvas.current.replace(/[\r\n]/g, "");

            // if selection exists, delete it

            if (selectionTails.current && selectionTails.current[0]?.realIndex !== selectionTails.current[1].realIndex) {
                console.log(selectionTails.current);
                const selectedText = questionCanvas.current.slice(selectionTails.current[0].realIndex, selectionTails.current[1].realIndex);

                // for each tail the adj shoud be selection[0].nodeObject.startIndex and selection[selection.length - 1].nodeObject.endIndex only if latexType is text

                const firstTailAdj = selectionTails.current[0].realIndex;
                const secondTailAdj = selectionTails.current[1].realIndex;

                questionCanvas.current = questionCanvas.current.slice(0, firstTailAdj) + questionCanvas.current.slice(secondTailAdj);

                const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });

                const newFlatTree = flattenTreeToLeaves(expressionalLatex);
                const index = findIndex(newFlatTree, selectionTails.current[0].realIndex);
                const leaf = newFlatTree[index].nodeObject;

                newNodePath = leaf.path;
                newCanvasIndex = selectionTails.current[0].realIndex;
                newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

                if (leaf.type === "expression") {
                    newNodePath = [0];
                    newNodeId = '';
                    newCanvasIndex = 0;
                };

                selectionTails.current[0].realIndex = 0;
                selectionTails.current[1].realIndex = 0;

                setSelection([]);
                setPrevNodePath(newNodePath);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvas(expressionalLatex);
                setCanvasFlatTree(newFlatTree);
                setCanvasIndex(newCanvasIndex);
                setSelection([]);
                return;
            };

            if (canvas.length === 0 || newCanvasIndex < 1) return;

            let toBeDeleted = questionCanvas.current[newCanvasIndex - 1];
            let preToBeDeleted = questionCanvas.current[newCanvasIndex - 2];

            const index = findIndexReverse(flatTree, newCanvasIndex - 1);
            const path = flatTree[index]?.nodeObject?.path?.slice(0, 1);
            const node = getNodeAtPath(canvas, path);

            // bad code fix later
            const specialCase = path ? node?.type === "newline" : false;

            if (specialCase) {
                questionCanvas.current = questionCanvas.current.slice(0, node?.startIndex) + questionCanvas.current.slice(node?.endIndex);
                const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
                const newFlatTree = flattenTreeToLeaves(expressionalLatex);

                const index = findIndexReverse(newFlatTree, node?.startIndex);
                const leaf = newFlatTree[index].nodeObject;

                newNodePath = leaf.path;
                newCanvasIndex = newCanvasIndex - 10;
                newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

                if (leaf.type === "expression") {
                    newNodePath = [0];
                    newCanvasIndex = 0;
                    newNodeId = '';
                }

                setSelection([]);
                setPrevNodePath(newNodePath);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvas(expressionalLatex);
                setCanvasFlatTree(newFlatTree);
                setCanvasIndex(newCanvasIndex);
                return;
            };

            if (toBeDeleted === "}") {
                console.log("YATA-1");
                const dem = flatTree[flatIndex - 1].nodeObject;

                newNodePath = dem.path;
                newCanvasIndex = dem.endIndex;
                newNodeId = `${id}-${dem.startIndex}-${dem.endIndex}`;

                console.log(dem);

                if (dem?.type === "text" && dem?.destructive) {
                    questionCanvas.current = questionCanvas.current.slice(0, dem.endIndex - 1) + questionCanvas.current.slice(dem.endIndex);
                    const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
                    newCanvasIndex--;
                    setCanvas(expressionalLatex);
                    setCanvasFlatTree(flattenTreeToLeaves(expressionalLatex));
                };

                setPrevNodePath(newNodePath);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvasIndex(newCanvasIndex);
                return;
            };

            if (toBeDeleted === "{" && grandParent?.type === "text" && grandParent?.latexText?.length > 0) {
                console.log("YATA-2");
                // this is different to destructuring as when you delete at the strat of bold, italic etc text you shouldn't destructure unless empty
                // need to delete the one before and maintain position

                if (grandParent.startIndex === 0) return; // this is because this must mean they're the "first" node so to avoid duplication via slicing we stop here

                questionCanvas.current = questionCanvas.current.slice(0, grandParent.startIndex - 1) + questionCanvas.current.slice(grandParent.startIndex);

                const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
                const newFlatTree = flattenTreeToLeaves(expressionalLatex);
                const index = findIndex(newFlatTree, grandParent.startIndex - 1);

                const leaf = newFlatTree[index].nodeObject;

                newNodePath = leaf.path;
                newCanvasIndex = leaf.endIndex;
                newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

                setPrevNodePath(newNodePath);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvas(expressionalLatex);
                setCanvasFlatTree(flattenTreeToLeaves(expressionalLatex));
                setCanvasIndex(newCanvasIndex);
                return;
            };

            questionCanvas.current = questionCanvas.current.slice(0, newCanvasIndex - 1) + questionCanvas.current.slice(newCanvasIndex);

            const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });

            // temp solution for \\newline exception
            // const specialCase = preToBeDeleted === "}" && aux

            if (toBeDeleted === "{") {
                console.log("YATA-3");
                // destructuring logic
                const normal = questionCanvas.current.slice(0, grandParent?.startIndex);
                const post = questionCanvas.current.slice(grandParent?.endIndex - 1);

                const args = grandParent?.children?.map(container => container?.nodeObject?.latexText);
                const unwrappedText = args?.join("");

                questionCanvas.current = normal + unwrappedText + post;

                const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
                const newFlatTree = flattenTreeToLeaves(expressionalLatex);

                console.log(flatIndex);

                // very elegant
                const gP = nodePath[nodePath.length - 2]; // numerator = 0, denominator = 1, other dynamically also = n+1
                const nodeWeWant = flatTree[flatIndex - 1].nodeObject;
                const funcAdj = (gP > 0) * (grandParent.functionName.length + 2); // +1 for "/" and +1 for first "{" e.g. "\frac{"
                const adjustedIndex = nodeWeWant.startIndex - funcAdj;

                const p = findIndex(newFlatTree, adjustedIndex);
                const leaf = newFlatTree[p].nodeObject;

                newNodePath = leaf.path;
                newCanvasIndex = adjustedIndex + nodeWeWant.latexText.length;
                newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

                if (leaf.type === "expression") {
                    newNodePath = [0];
                    newNodeId = '';
                    newCanvasIndex = 0;
                };

                setPrevNodePath(newNodePath);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvas(expressionalLatex);
                setCanvasFlatTree(flattenTreeToLeaves(expressionalLatex));
                setCanvasIndex(newCanvasIndex);
                return;
            };

            const newFlatTree = flattenTreeToLeaves(expressionalLatex);
            const diff = flatTree.length - newFlatTree.length;

            if (diff === 0) {
                console.log("YATA-4");
                newCanvasIndex--;
            } else {
                console.log("YATA-5");
                const index = findIndexReverse(newFlatTree, newCanvasIndex - 1);

                // const leaf = newFlatTree[Math.max(0, flatIndex - diff)].nodeObject; // old version, does not work as intended for case 1
                const leaf = newFlatTree[index].nodeObject; // works better so far

                newNodePath = leaf.path;
                newCanvasIndex = newCanvasIndex - 1;
                newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

                if (newCanvasIndex < leaf.startIndex || newCanvasIndex > leaf.endIndex) {
                    newCanvasIndex = leaf.startIndex;
                };
            };

            if (selectionTails.current) {
                selectionTails.current[0].realIndex = 0;
                selectionTails.current[1].realIndex = 0;
            };

            setSelection([]);
            setPrevNodePath(newNodePath);
            setNodePath(newNodePath);
            setNodeId(newNodeId);
            setCanvas(expressionalLatex);
            setCanvasFlatTree(newFlatTree);
            setCanvasIndex(newCanvasIndex);
        }
        else if (!keyFilter.includes(key)) {
            let input = key;

            // implement select all system

            if (input === "a" && (ctrlKey || metaKey)) {
                console.log('here');
                if (flatTree.length === 0) return;

                const range = canvasFlatTree;
                const shortestPathLength = Math.min(...range.map(item => item.nodeObject.path.length));
                const better = refineAndDeduplicatePaths(range, canvas, shortestPathLength);

                const first = better[0].nodeObject;
                const last = better[better.length - 1].nodeObject;

                const firstNodeCount = `${id}-${first.startIndex}-${first.endIndex}`;
                const lastNodeCount = `${id}-${last.startIndex}-${last.endIndex}`;

                setSelection(better);
                setNodePath([]);

                selectionTails.current = [{ nodeCount: firstNodeCount, startIndex: 0, realIndex: 0 }, { nodeCount: lastNodeCount, endIndex: last.latexText.length, realIndex: questionCanvas.current.length }];
                return;
            };

            // implement copy ctrl c system

            if (input === "c" && (ctrlKey || metaKey)) {
                if (selection.length > 0) {
                    // we need to modify the line below similarly to how we did it for the backspace system

                    const firstTailAdj = selectionTails.current[0].realIndex
                    const secondTailAdj = selectionTails.current[1].realIndex

                    const selectedText = questionCanvas.current.slice(firstTailAdj, secondTailAdj);

                    CopyMe(selectedText);
                };
                return;
            };

            const archiveState = {
                nodePath: nodePath,
                canvasIndex: canvasIndex,
                nodeId: nodeId,
                canvas: canvas,
                canvasFlatTree: canvasFlatTree,
                questionCanvas: questionCanvas.current,
                selectionTails: selectionTails.current,
                selection: selection,
            };

            // new line system

            if (input === "Enter") {
                input = "\\newline{}";
                questionCanvas.current = questionCanvas.current.slice(0, newCanvasIndex) + input + questionCanvas.current.slice(newCanvasIndex);
                let { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
                let newFlatTree = flattenTreeToLeaves(expressionalLatex);
                const navFlatTree = newFlatTree.filter(item => getNodeAtPath(expressionalLatex, item.nodeObject.path.slice(0, -2))?.type !== "newline");

                const index = findIndex(navFlatTree, newCanvasIndex + 10);
                const leaf = navFlatTree[index].nodeObject;

                newNodePath = leaf.path;
                newCanvasIndex = leaf.startIndex;
                newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

                setSelection([]);
                setPrevNodePath(newNodePath);
                setCanvasIndex(newCanvasIndex);
                setCanvas(expressionalLatex);
                setCanvasFlatTree(newFlatTree);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                return;
            };

            // paste system

            if (input === "v" && (ctrlKey || metaKey)) {
                navigator.clipboard.readText().then(clipText => {
                    const firstTailAdj = selection.length > 0 ? selectionTails.current[0].realIndex : newCanvasIndex;
                    const secondTailAdj = selection.length > 0 ? selectionTails.current[1].realIndex : newCanvasIndex;

                    if (selectionTails.current) {
                        console.log(questionCanvas.current.slice(0, firstTailAdj) + clipText + questionCanvas.current.slice(secondTailAdj));
                    };

                    questionCanvas.current = questionCanvas.current.slice(0, firstTailAdj) + clipText + questionCanvas.current.slice(secondTailAdj);

                    const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
                    const newFlatTree = flattenTreeToLeaves(expressionalLatex);

                    newCanvasIndex = firstTailAdj + clipText.length;

                    const index = findIndex(newFlatTree, newCanvasIndex);
                    const leaf = newFlatTree[index].nodeObject;

                    newNodePath = leaf.path;
                    newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

                    epicenterSelect.current = {
                        // null values as these will be determined by mouseMove in the next mouse movement
                        eNodePath: nodePath,
                        eNode: getNodeAtPath(expressionalLatex, newNodePath),
                        eCanvasIndex: newCanvasIndex,
                        eTailIndex: newCanvasIndex - getNodeAtPath(expressionalLatex, newNodePath).startIndex,
                        oneTailIndex: newCanvasIndex - getNodeAtPath(expressionalLatex, newNodePath).startIndex,
                        verticalDirection: null,
                        horizontalDirection: null,
                        x1: e.clientX,
                        y1: e.clientY,
                        x2: null,
                        y2: null,
                        eNodeCount: newNodeId,
                        startTailIndex: newCanvasIndex - getNodeAtPath(expressionalLatex, newNodePath).startIndex,
                        endTailIndex: null,
                    };

                    if (selectionTails.current) {
                        selectionTails.current[0].realIndex = 0;
                        selectionTails.current[1].realIndex = 0;
                    };

                    setSelection([]);
                    setPrevNodePath(newNodePath);
                    setNodePath(newNodePath);
                    setNodeId(newNodeId);
                    setCanvas(expressionalLatex);
                    setCanvasFlatTree(flattenTreeToLeaves(expressionalLatex));
                    setCanvasIndex(newCanvasIndex);
                });
                return;
            };

            if (input === "z" && (ctrlKey || metaKey)) {
                const actions = history.current.actions;
                if (actions.length === 0) return;

                if (shiftKey) {
                    console.log('here', history.current);
                    if (history.current.cursor === actions.length) return;

                    const lastAction = actions[history.current.cursor];

                    console.log(lastAction);

                    setCanvas(lastAction.canvas);
                    setNodePath(lastAction.nodePath);
                    setCanvasIndex(lastAction.canvasIndex);
                    setNodeId(lastAction.nodeId);
                    setCanvasFlatTree(lastAction.canvasFlatTree);
                    setSelection(lastAction.selection);
                    selectionTails.current = lastAction.selectionTails;
                    questionCanvas.current = lastAction.questionCanvas;

                    history.current.cursor++;
                } else {
                    if (history.current.cursor === 0) return;

                    const lastAction = actions[history.current.cursor - 1];

                    setCanvas(lastAction.canvas);
                    setNodePath(lastAction.nodePath);
                    setCanvasIndex(lastAction.canvasIndex);
                    setNodeId(lastAction.nodeId);
                    setCanvasFlatTree(lastAction.canvasFlatTree);
                    setSelection(lastAction.selection);
                    selectionTails.current = lastAction.selectionTails;
                    questionCanvas.current = lastAction.questionCanvas;

                    history.current.cursor--;
                };
                return;
            };

            history.current.actions = history.current.actions.slice(0, history.current.cursor);
            history.current.actions.push(archiveState);
            history.current.cursor++;

            if (input === "{" || input === "}") return;
            questionCanvas.current = questionCanvas.current.replace(/[\r\n]/g, "");

            // fraction shortcut
            if (input === "/") {
                if (flatTree[flatIndex]?.nodeObject?.path.length > 11) return; // limit max num nest due to serious performance issues
                console.log(auxNode?.type !== "newline_placeholder");
                if (auxNode?.type !== "newline_placeholder" && ((auxNode?.latexType?.includes("_placeholder") && auxNode?.direction === "right") || (auxNode?.latexType !== "placeholder" && newCanvasIndex === auxNode?.startIndex && grandParent?.latexType !== "latex"))) {
                    // get left node of placeholder as you want to wrap that, not a placeholder
                    auxNode = getNodeAtPath(canvas, [...newNodePath.slice(0, -1), newNodePath[newNodePath.length - 1] - 1]);
                };

                let banned = bannedInputs.includes(questionCanvas.current[newCanvasIndex - 1]) && newCanvasIndex !== auxNode?.startIndex; // banned inputs like +,-, , etc
                let banAdjust = banned ? 1 : 0; // assuming inputs are 1 char long

                let rawWrap = questionCanvas.current.slice(auxNode?.startIndex, newCanvasIndex);
                let toWrap = questionCanvas.current.slice(auxNode?.startIndex + banAdjust, newCanvasIndex);
                let wrapped = `\\frac{${toWrap.trim()}}{}`;

                let updatedLatex;

                const functionName = "frac";

                // NOTE: redesign optimisation needed, works logically for surface level but not deeper.

                if (selection.length > 0) {
                    const firstTailAdj = selectionTails.current[0].realIndex;
                    const secondTailAdj = selectionTails.current[1].realIndex;

                    rawWrap = questionCanvas.current.slice(firstTailAdj, secondTailAdj);
                    toWrap = questionCanvas.current.slice(firstTailAdj, secondTailAdj);
                    console.log(toWrap);
                    wrapped = `\\frac{${toWrap.trim()}}{}`;

                    questionCanvas.current = questionCanvas.current.slice(0, firstTailAdj) + wrapped + questionCanvas.current.slice(secondTailAdj)

                    const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
                    const newFlatTree = flattenTreeToLeaves(expressionalLatex);

                    const { expressionalLatex: wrapLatex } = LatexFormatter({ text: rawWrap, id });
                    const wrappedTree = flattenTreeToLeaves(wrapLatex).filter(item => item.nodeObject?.type !== "expression");

                    const g = findIndex(newFlatTree, secondTailAdj + functionName.length + 1); // +1 for "/" and +1 for first "{" e.g. "\frac{"

                    console.log(wrappedTree, flatIndex, newFlatTree, g, newFlatTree[g].nodeObject);

                    let leaf = newFlatTree[g + 1].nodeObject;

                    if (leaf.latexType?.includes("_placeholder")) {
                        leaf = newFlatTree[g + 2].nodeObject;
                    };

                    newNodePath = leaf.path;
                    newCanvasIndex = leaf.endIndex;
                    newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;
                    updatedLatex = expressionalLatex;

                    if (selectionTails.current) {
                        selectionTails.current[0].realIndex = 0;
                        selectionTails.current[1].realIndex = 0;
                    };
                } else {
                    questionCanvas.current = questionCanvas.current.slice(0, auxNode?.startIndex + banAdjust) + wrapped + questionCanvas.current.slice(newCanvasIndex);
                    const { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
                    const newFlatTree = flattenTreeToLeaves(expressionalLatex);

                    const { expressionalLatex: wrapLatex } = LatexFormatter({ text: rawWrap, id });
                    const wrappedTree = flattenTreeToLeaves(wrapLatex).filter(item => item.nodeObject?.type !== "expression");

                    const functionName = "frac";
                    const p = findIndex(newFlatTree, auxNode?.startIndex + functionName.length + 2); // +1 for "/" and +1 for first "{" e.g. "\frac{"
                    const q = p + wrappedTree.length + ((flatIndex < 0) * 1);
                    const leaf = newFlatTree[q].nodeObject;

                    newNodePath = leaf.path;
                    newCanvasIndex = leaf.endIndex;
                    newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`;

                    if (selectionTails.current) {
                        selectionTails.current[0].realIndex = 0;
                        selectionTails.current[1].realIndex = 0;
                    };

                    setPrevNodePath(newNodePath);
                    setNodePath(newNodePath);
                    setNodeId(newNodeId);
                    setCanvasIndex(newCanvasIndex);
                    setCanvas(expressionalLatex);
                    setCanvasFlatTree(flattenTreeToLeaves(expressionalLatex));
                    return;
                };

                if (selectionTails.current) {
                    selectionTails.current[0].realIndex = 0;
                    selectionTails.current[1].realIndex = 0;
                };

                setSelection([]);
                setPrevNodePath(newNodePath);
                setNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvasIndex(newCanvasIndex);
                setCanvas(updatedLatex);
                setCanvasFlatTree(flattenTreeToLeaves(updatedLatex));
                return;
            };

            if (input === "Tab") {
                // right
                const flatTree = canvasFlatTree.filter(item => item.nodeObject?.type !== "expression");
                const flatIndex = Math.min(flatTree.findIndex(item => arraysAreEqual(item.nodeObject.path, nodePath)), flatTree.length);

                if (shiftKey && (flatTree.length === 0 || flatIndex === 0)) return;
                if (!shiftKey && (flatTree.length === 0 || flatIndex === flatTree.length - 1)) return;

                const adj = shiftKey ? - 1 : 1;

                const newLeaf = flatTree[flatIndex + adj];

                if (newLeaf) {
                    const newNode = newLeaf.nodeObject;
                    newNodePath = newNode.path;
                    newCanvasIndex = shiftKey ? newNode.endIndex : newNode.startIndex;
                    newNodeId = `${id}-${newNode.startIndex}-${newNode.endIndex}`;
                    if (canvasIndex === newCanvasIndex) {
                        newCanvasIndex = shiftKey ? newNode.startIndex : newNode.endIndex;
                    };
                };

                setPrevNodePath(newNodePath);
                setNodeId(newNodeId);
                setCanvasIndex(newCanvasIndex);
                setNodePath(newNodePath);
                return;
            };

            questionCanvas.current = questionCanvas.current.slice(0, newCanvasIndex) + input + questionCanvas.current.slice(newCanvasIndex);
            let { expressionalLatex } = LatexFormatter({ text: questionCanvas.current, id });
            // let newFlatTree = expressionalLatex.localFlatTree;
            let newFlatTree = flattenTreeToLeaves(expressionalLatex);

            newCanvasIndex++;

            let nodeChange = false;

            // need a better way to detect new nodes
            const validFunctions = ["frac", "sum", "product", "bold", "italic", "small", "big", "newline", "int"]; // Add more valid functions as needed

            let functionName; // Variable to store the matched function name

            // Check if the input matches any valid function
            for (const func of validFunctions) {
                if (questionCanvas.current.slice(0, newCanvasIndex).endsWith(`\\${func}`)) {
                    functionName = func;
                    nodeChange = true;
                    break;
                }
            };

            if (nodeChange) {
                const archiveState = {
                    nodePath: nodePath,
                    canvasIndex: canvasIndex,
                    selectionTails: selectionTails.current,
                    nodeId: nodeId,
                    canvas: canvas,
                    canvasFlatTree: flatTree,
                    selection: selection,
                    canvasByLines: canvasByLines,
                    questionCanvas: questionCanvas.current,
                };

                history.current.actions = history.current.actions.slice(0, history.current.cursor);
                history.current.actions.push(archiveState);
                history.current.cursor++;

                // this means converted into a placeholder
                // placeholders are for functions
                // this might not work for bold
                const currentNode = flatTree[flatIndex].nodeObject;
                const index = findIndexReverse(newFlatTree, auxNode?.endIndex);
                const newNode = newFlatTree[index].nodeObject;
                let newGrandParent = getNodeAtPath(expressionalLatex, newNode.path.slice(0, -2));

                questionCanvas.current = questionCanvas.current.slice(0, newCanvasIndex) + (newGrandParent?.pairs ? newGrandParent.pairs : "") + questionCanvas.current.slice(newCanvasIndex);
                expressionalLatex = LatexFormatter({ text: questionCanvas.current, id }).expressionalLatex;

                newNodePath = newNode.path;
                newCanvasIndex = newCanvasIndex + ((newGrandParent?.pairs?.length > 1) * 1); // \frac| -> \frac{|} +1 implied using newNode wrong index position
                if (newGrandParent.type === "newline") {
                    newCanvasIndex++;
                };
                newNodeId = `${id}-${newNode.startIndex}-${newNode.endIndex}`;

            } else if (newCanvasIndex > newFlatTree[flatIndex]?.nodeObject?.endIndex) {
                console.log(newFlatTree);
                const newLeaf = newFlatTree[flatIndex + 1].nodeObject;
                newNodePath = newLeaf.path;
                newCanvasIndex = newLeaf.startIndex;
                newCanvasIndex += input.length; // usually +1 but some special cases like escaped curly braces mean invisible char so +2
                newNodeId = `${id}-${newLeaf.startIndex}-${newLeaf.endIndex}`;
            };

            if (selection.length > 0) {
                const firstTailAdj = selectionTails.current[0].realIndex;
                const secondTailAdj = selectionTails.current[1].realIndex;

                questionCanvas.current = questionCanvas.current.slice(0, firstTailAdj) + input + questionCanvas.current.slice(secondTailAdj + 1);

                const stuff = LatexFormatter({ text: questionCanvas.current, id })

                expressionalLatex = stuff.expressionalLatex;
                newFlatTree = flattenTreeToLeaves(expressionalLatex);

                const newIndex = findIndex(newFlatTree, firstTailAdj);
                const leaf = newFlatTree[newIndex].nodeObject;

                newNodePath = leaf.path;
                newCanvasIndex = Math.min(leaf.startIndex + selectionTails.current[0].startIndex + 1, leaf.endIndex);
            };

            // const archiveState = {
            //     nodePath: newNodePath,
            //     canvasIndex: newCanvasIndex,
            //     nodeId: newNodeId,
            //     canvas: expressionalLatex,
            //     canvasFlatTree: newFlatTree,
            //     questionCanvas: questionCanvas.current,
            //     selectionTails: selectionTails.current,
            //     selection: selection,
            // };

            console.log(expressionalLatex);

            if (selectionTails.current) {
                selectionTails.current[0].realIndex = 0;
                selectionTails.current[1].realIndex = 0;
            };

            setSelection([]);
            setPrevNodePath(newNodePath);
            setCanvasIndex(newCanvasIndex);
            setCanvas(expressionalLatex);
            setCanvasFlatTree(newFlatTree);
            setNodePath(newNodePath);
            setNodeId(newNodeId);
        };
    };

    const handleClick = (e) => {
        let closestDomChild = null;
        let closestDistance = Infinity; // Initialize with a high value for comparison
        let closestNodeCount = 0;
        let closestNodeIndex = 0;
        let closestDirection;

        if (selectionTails.current) {
            selectionTails.current[0].realIndex = 0;
            selectionTails.current[1].realIndex = 0;
        };

        setSelection([]);

        const flatTree = canvasFlatTree.filter(item => item.nodeObject.type !== "expression");
        if (flatTree.length === 0) return;
        const lineIndex = findClickedLine(canvasByLines.lineBreaks, e.clientX, e.clientY, id);

        if (lineIndex === -1) return; // out of bounds

        const line = canvasByLines.lineBreaks[lineIndex];

        const pool = flattenTreeToLeaves({ nodeObject: { children: canvas.nodeObject.children.slice(line.startIndex, line.endIndex + 1) } }, [], line.startIndex)

        let index;

        pool.forEach((child, index) => {
            const nodeCount = `${id}-${child.nodeObject.startIndex}-${child.nodeObject.endIndex}`;
            const domChild = document.querySelector(`[nodeCount="${nodeCount}"]`);

            if (domChild) {
                const charRects = getCharacterRects2(domChild); // Get character rects for the domChild

                if (charRects.length === 0) {
                    // Use the event's clientX and clientY as the target coordinates
                    const rectChild = domChild.getBoundingClientRect();
                    const targetX = e.clientX;
                    const targetY = e.clientY;
                    const charCenterX = (rectChild.left + rectChild.right) / 2;
                    const charCenterY = (rectChild.top + rectChild.bottom) / 2;

                    // Compute the actual distance using the Pythagorean theorem
                    const distance = Math.sqrt(Math.pow(targetX - charCenterX, 2) + Math.pow(targetY - charCenterY, 2));

                    if (distance <= closestDistance) {
                        closestDistance = distance;
                        closestDomChild = domChild;
                        closestNodeCount = nodeCount;
                        closestNodeIndex = 0; // Set the index of the closest character

                        // Determine the direction based on the x-coordinate
                        if (charCenterX < targetX) {
                            closestDirection = 'left';
                        } else {
                            closestDirection = 'right';
                        }
                    }
                } else {
                    charRects.forEach((rectChild, charIndex) => {
                        // Use the event's clientX and clientY as the target coordinates
                        const targetX = e.clientX;
                        const targetY = e.clientY;
                        const charCenterX = (rectChild.left + rectChild.right) / 2;
                        const charCenterY = (rectChild.top + rectChild.bottom) / 2;

                        // Compute the actual distance using the Pythagorean theorem
                        const distance = Math.sqrt(Math.pow(targetX - charCenterX, 2) + Math.pow(targetY - charCenterY, 2));

                        if (distance <= closestDistance) {
                            closestDistance = distance;
                            closestDomChild = domChild;
                            closestNodeCount = nodeCount;
                            closestNodeIndex = charIndex; // Set the index of the closest character

                            // Determine the direction based on the x-coordinate
                            if (charCenterX < targetX) {
                                closestDirection = 'left';
                            } else {
                                closestDirection = 'right';
                            }
                        }
                    });
                }

            }
        });

        // After finding the closest, you can use closestDomChild, closestNodeCount, etc. as needed
        // Example: console.log(closestDomChild, closestNodeCount, closestNodeIndex, closestDirection);

        index = flatTree.findIndex(item => `${id}-${item.nodeObject.startIndex}-${item.nodeObject.endIndex}` === closestNodeCount);

        let leaf = flatTree[index]?.nodeObject;

        let newNodePath = leaf?.path;
        let newCanvasIndex = leaf?.startIndex + closestNodeIndex + (closestDirection === "left" ? 1 : 0);
        let newNodeId = `${id}-${leaf?.startIndex}-${leaf?.endIndex}`

        // fix later: temp solution for \\newline index will default to 0 if length ===  1;
        if (pool.length === 1 && getNodeAtPath(canvas, pool[0].nodeObject.path.slice(0, -2))?.type === "newline") {
            leaf = getNodeAtPath(canvas, pool[0].nodeObject.path.slice(0, -2));

            newNodePath = leaf.path;
            newCanvasIndex = leaf.startIndex;
            newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`
        };

        if (leaf?.latexText === " ") {
            if (closestDirection === "left") {
                if (flatTree[index + 1] && flatTree[index + 1].nodeObject?.type === "text") {
                    leaf = flatTree[index + 1].nodeObject;
                    newCanvasIndex = leaf.startIndex;
                    newNodeId = `${id}-${leaf.startIndex}-${leaf.endIndex}`
                    newNodePath = leaf.path;
                };
            } else {
            };
        };

        if (leaf?.latexType === "placeholder" || leaf?.latexType?.includes("_placeholder")) {
            newCanvasIndex = leaf.startIndex;
        };

        setPrevNodePath(newNodePath);
        setNodePath(newNodePath);
        setNodeId(newNodeId);
        setCanvasIndex(newCanvasIndex);
        return { newNodePath, newCanvasIndex, newNodeCount: `${id}-${leaf.startIndex}-${leaf.endIndex}` };
    };

    const handleMouseDown = (e) => {
        mouseDownTime.current = Date.now();
        selectionStart.current = { x: e.clientX, y: e.clientY };

        const clickData = handleClick(e);

        if (selectionTails.current) {
            selectionTails.current[0].realIndex = 0;
            selectionTails.current[1].realIndex = 0;
        };

        setSelection([]);

        if (!clickData) return;

        const { newNodeCount, newCanvasIndex, newNodePath } = clickData;

        epicenterSelect.current = {
            // null values as these will be determined by mouseMove in the next mouse movement
            eNodePath: nodePath,
            eNode: getNodeAtPath(canvas, newNodePath),
            eCanvasIndex: newCanvasIndex,
            eTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
            oneTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
            verticalDirection: null,
            horizontalDirection: null,
            x1: e.clientX,
            y1: e.clientY,
            x2: null,
            y2: null,
            eNodeCount: newNodeCount,
            startTailIndex: newCanvasIndex - getNodeAtPath(canvas, newNodePath).startIndex,
            endTailIndex: null,
        };

        selectionTimeout.current = setTimeout(() => {
            setSelecting(true);
        }, SELECT_DELAY);
    };

    const handleMouseMove = (e) => {
        if (!selecting) return;

        const { eNodeCount, eTailIndex, oneTailIndex, eCanvasIndex } = epicenterSelect.current;
        const lineIndex = findClickedLine(canvasByLines.lineBreaks, e.clientX, e.clientY, id);

        if (lineIndex === -1) return; // out of bounds

        const line = canvasByLines.lineBreaks[lineIndex];
        const pool = flattenTreeToLeaves({ nodeObject: { children: canvas.nodeObject.children.slice(line.startIndex, line.endIndex + 1) } }, [], line.startIndex);

        let closestDomChild = null;
        let closestDistance = Infinity; // Initialize with a high value for comparison
        let closestNodeCount = 0;
        let closestNodeIndex = 0;
        let closestDirection;
        let closestIndex = pool[0].nodeObject.startIndex - 9;

        pool.forEach((child, index) => {
            const nodeCount = `${id}-${child.nodeObject.startIndex}-${child.nodeObject.endIndex}`;
            const domChild = document.querySelector(`[nodeCount="${nodeCount}"]`);

            if (domChild) {
                const charRects = getCharacterRects2(domChild); // Get character rects for the domChild

                if (charRects.length === 0) {
                    // Use the event's clientX and clientY as the target coordinates
                    const rectChild = domChild.getBoundingClientRect();
                    const targetX = e.clientX;
                    const targetY = e.clientY;
                    const charCenterX = (rectChild.left + rectChild.right) / 2;
                    const charCenterY = (rectChild.top + rectChild.bottom) / 2;

                    // Compute the actual distance using the Pythagorean theorem
                    const distance = Math.sqrt(Math.pow(targetX - charCenterX, 2) + Math.pow(targetY - charCenterY, 2));

                    if (distance <= closestDistance) {
                        closestDistance = distance;
                        closestDomChild = domChild;
                        closestNodeCount = nodeCount;
                        closestNodeIndex = 0; // Set the index of the closest character
                        closestIndex = child.nodeObject.startIndex;

                        // Determine the direction based on the x-coordinate
                        if (charCenterX < targetX) {
                            closestDirection = 'left';
                        } else {
                            closestDirection = 'right';
                        }
                    }
                } else {
                    charRects.forEach((rectChild, charIndex) => {
                        // Use the event's clientX and clientY as the target coordinates
                        const targetX = e.clientX;
                        const targetY = e.clientY;
                        const charCenterX = (rectChild.left + rectChild.right) / 2;
                        const charCenterY = (rectChild.top + rectChild.bottom) / 2;

                        // Compute the actual distance using the Pythagorean theorem
                        const distance = Math.sqrt(Math.pow(targetX - charCenterX, 2) + Math.pow(targetY - charCenterY, 2));

                        if (distance <= closestDistance) {
                            closestDistance = distance;
                            closestDomChild = domChild;
                            closestNodeCount = nodeCount;
                            closestNodeIndex = charIndex; // Set the index of the closest character
                            closestIndex = child.nodeObject.startIndex + charIndex;

                            // Determine the direction based on the x-coordinate
                            if (charCenterX < targetX) {
                                closestDirection = 'left';
                            } else {
                                closestDirection = 'right';
                            }

                            if (closestDirection === 'left') {
                                closestNodeIndex++;
                                closestIndex++;
                            };
                        }
                    });
                }

            }
        });

        // const eIndex = canvasFlatTree.findIndex(item => `${id}-${item.nodeObject.startIndex}-${item.nodeObject.endIndex}` === eNodeCount);
        // jumper
        const eIndex = findIndex(canvasFlatTree, eCanvasIndex);
        const index = canvasFlatTree.findIndex(item => `${id}-${item.nodeObject.startIndex}-${item.nodeObject.endIndex}` === closestNodeCount);

        const startIndex = Math.min(eIndex, index);
        const endIndex = Math.max(eIndex, index);

        const range = canvasFlatTree.slice(startIndex, endIndex + 1);
        const shortestPathLength = Math.min(...range.map(item => item.nodeObject.path.length));
        const better = refineAndDeduplicatePaths(range, canvas, shortestPathLength);

        if (better.length !== 0) {
            if (index > eIndex) {
                selectionTails.current = [{ treeIndex: eIndex, node: better[0].nodeObject, nodeCount: eNodeCount, startIndex: eTailIndex }, { treeIndex: index, node: better[better.length - 1].nodeObject, nodeCount: closestNodeCount, endIndex: closestNodeIndex, startIndex: closestNodeIndex }];
                epicenterSelect.current.horizontalDirection = "right";
            } else if (index < eIndex) {
                selectionTails.current = [{ treeIndex: index, node: better[0].nodeObject, nodeCount: closestNodeCount, startIndex: closestNodeIndex }, { treeIndex: eIndex, node: better[better.length - 1].nodeObject, nodeCount: eNodeCount, endIndex: eTailIndex, startIndex: eTailIndex }];
                epicenterSelect.current.horizontalDirection = "left";
            } else if (index === eIndex) {
                selectionTails.current = [{ treeIndex: eIndex, node: better[0].nodeObject, nodeCount: eNodeCount, startIndex: Math.min(eTailIndex, closestNodeIndex) }, { treeIndex: eIndex, node: better[better.length - 1].nodeObject, nodeCount: eNodeCount, endIndex: Math.max(eTailIndex, closestNodeIndex), startIndex: Math.max(eTailIndex, closestNodeIndex) }];

                if (closestNodeIndex > eTailIndex) {
                    epicenterSelect.current.horizontalDirection = "right";
                } else {
                    epicenterSelect.current.horizontalDirection = "left";
                };
            };

            let tempECanvasIndex = eCanvasIndex;

            let s1 = Math.min(tempECanvasIndex, closestIndex);
            let s2 = Math.max(tempECanvasIndex, closestIndex);

            if (better[0].nodeObject.latexType !== "text" && !better[0].nodeObject.latexType.includes("_placeholder")) {
                selectionTails.current[0].startIndex = 0;
                s1 = better[0].nodeObject.startIndex;
            };

            if (better[better.length - 1].nodeObject.latexType !== "text" && !better[better.length - 1].nodeObject.latexType.includes("_placeholder")) {
                selectionTails.current[1].endIndex = better[better.length - 1].nodeObject.endIndex - better[better.length - 1].nodeObject.startIndex;
                s2 = better[better.length - 1].nodeObject.endIndex;
            };

            // console.log(better, pool, lineIndex);
            // console.log(s1, s2);
            // console.log(questionCanvas.current.slice(s1, s2));

            setSelection(better);

            selectionTails.current[0].realIndex = s1;
            selectionTails.current[1].realIndex = s2;
        };
    };

    const handleMouseLeave = (e) => {
        setSelecting(false);
    };

    const handleBlur = (e) => {
        // setSelection([]);
    };

    const handleMouseUp = (e) => {
        clearTimeout(selectionTimeout.current);
        const mouseUpTime = Date.now();
        const diff = mouseUpTime - mouseDownTime.current;

        if (diff < SELECT_DELAY) {
            // Handle click logic
            setSelecting(false);
        } else {
            // End of selecting
            setSelecting(false);
        }
    };

    const RecursiveMapper = ({ node, path }) => {
        if (node.nodeObject.children) {
            // problem not here
            const { children, parent } = node.nodeObject;

            if (children) {
                const renderedChildren = children.map((child, index) => (
                    <RecursiveMapper node={child} path={path.concat(index)} />
                ));

                if (parent) {
                    const samePath = arraysAreEqual(path, nodePath);
                    const newNode = getNodeAtPath(canvas, nodePath);
                    const { startIndex, endIndex } = newNode;

                    const nodeCount = `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}`;

                    // check if nodeCount in selection
                    const selectionIndex = selection?.findIndex(item => `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}` === `${id}-${item.nodeObject.startIndex}-${item.nodeObject.endIndex}`)

                    // Clone the parent element and insert the rendered children
                    return (
                        <>
                            {(samePath && canvasIndex === startIndex) && node.nodeObject.parent && cursor}
                            {React.cloneElement(parent, { ref: node.nodeObject.ref, nodeCount: `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}`, className: `${parent.props.className} ${selectionIndex !== -1 ? styles.selectedItem : ''}` }, ...renderedChildren)}
                            {(samePath && canvasIndex === endIndex) && node.nodeObject.parent && cursor}
                        </>
                    );
                };

                return renderedChildren;
            };
        };

        const currentNode = getNodeAtPath(canvas, nodePath);

        if (selection.length > 0) {
            const newElement = React.cloneElement(node.nodeObject.parent, {
                // Spread existing props and then override or add additional props
                ...node.nodeObject.parent.props,
                className: `${node.nodeObject.parent.props.className} ${styles.currentNode}`, // Add a new class
                ref: node.nodeObject.ref,
                nodeCount: `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}`,
                // Any other props you want to modify or add can go here
            });

            const isFirstTail = `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}` === `${id}-${selection[0].nodeObject.startIndex}-${selection[0].nodeObject.endIndex}`;
            const isSecondTail = `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}` === `${id}-${selection[selection.length - 1].nodeObject.startIndex}-${selection[selection.length - 1].nodeObject.endIndex}`;
            // instead compare nodeCount redefine isFirstTail

            if (isFirstTail || isSecondTail) {
                const splitted = splitReactElementToSpans(newElement, canvasIndex, node.nodeObject, cursor, id, selectionTails.current, selection);

                return (
                    <>
                        {splitted}
                    </>
                );
            }
        };

        if (arraysAreEqual(path, nodePath) && node.nodeObject.parent) {
            const newElement = React.cloneElement(node.nodeObject.parent, {
                // Spread existing props and then override or add additional props
                ...node.nodeObject.parent.props,
                className: `${node.nodeObject.parent.props.className} ${styles.currentNode}`, // Add a new class
                ref: node.nodeObject.ref,
                nodeCount: `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}`,
                // Any other props you want to modify or add can go here
            });

            if (!editable) return newElement;

            if (canvasIndex === currentNode.startIndex) {
                return (
                    <>
                        {cursor}
                        {newElement}
                    </>
                );
            };

            const splitted = splitReactElementToSpans(newElement, canvasIndex, currentNode, cursor, id);

            return (
                <>
                    {splitted}
                </>
            );
        };

        // Handle other types of children or return parent directly

        const selectionIndex = selection.findIndex(item => `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}` === `${id}-${item.nodeObject.startIndex}-${item.nodeObject.endIndex}`)

        const newElement = React.cloneElement(node.nodeObject.parent, {
            // Spread existing props and then override or add additional props
            ...node.nodeObject.parent.props,
            className: `${node.nodeObject.parent.props.className} ${selectionIndex !== -1 ? styles.selectedItem : ""}`, // Add a new class
            ref: node.nodeObject.ref,
            nodeCount: `${id}-${node.nodeObject.startIndex}-${node.nodeObject.endIndex}`,
            // Any other props you want to modify or add can go here
        });

        return newElement;
    };

    return (
        <div style={{ width: calculatedLineWidth.current }} key={id + "-text-editor-container"} id={id + "-text-editor-container"} className={styles.textEditorContainer} tabIndex="0">
            <div
                className={styles.textEditor}
                onMouseDown={handleMouseDown}
                onKeyDownCapture={editable ? handleKeyDown : () => { }}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onBlur={handleBlur}
                tabIndex="0"
                id={id + "-text-editor"}
                key={id + "-text-editor"}
            >
                {
                    canvas?.nodeObject?.children?.length ?
                        canvasByLines?.nodeObject?.children?.map((line, lineIndex) => {
                            const base = canvasByLines?.lineBreaks[lineIndex]?.total - 1;

                            const lineContent = line?.nodeObject?.children.map((child, index) => {
                                const split = selection.length > 0 && (`${id}-${child.nodeObject.startIndex}-${child.nodeObject.endIndex}` === `${id}-${selection[0].nodeObject.startIndex}-${selection[0].nodeObject.endIndex}` || `${id}-${child.nodeObject.startIndex}-${child.nodeObject.endIndex}` === `${id}-${selection[selection.length - 1].nodeObject.startIndex}-${selection[selection.length - 1].nodeObject.endIndex}`);

                                if (split && child.nodeObject.type === "text") {
                                    return <RecursiveMapper node={child} path={[base + index]} />
                                };

                                // return child.nodeObject.fullParent;

                                const newElement = React.cloneElement(child.nodeObject.fullParent, {
                                    ...child.nodeObject.fullParent.props, // Spread existing props
                                    className: `${child.nodeObject.fullParent.props.className}`, // Modify className
                                    ref: child.nodeObject.ref,
                                    nodeCount: `${id}-${child.nodeObject.startIndex}-${child.nodeObject.endIndex}`,
                                });

                                // selectionIndex holds the truthy value of whether or not the child is in the selection array
                                const selectionIndex = selection.length > 0 && selectionTails.current[0].realIndex <= child.nodeObject.startIndex && selectionTails.current[1].realIndex >= child.nodeObject.endIndex;

                                if (!selectionIndex && editable && nodePath[0] === base + index) {
                                    return <RecursiveMapper node={child} path={[base + index]} />
                                };

                                if (child.nodeObject.type === "newline") {
                                    const newElement = React.cloneElement(child.nodeObject.fullParent, {
                                        ...child.nodeObject.fullParent.props, // Spread existing props
                                        className: `${child.nodeObject.fullParent.props.className} ${selectionIndex ? styles.selectedItem : ""}`, // Modify className
                                        ref: child.nodeObject.ref,
                                        nodeCount: `${id}-${child.nodeObject.startIndex}-${child.nodeObject.endIndex}`,
                                    });

                                    return newElement;
                                };

                                return (
                                    <span className={`${selectionIndex ? styles.selectedItem : ""}`}>
                                        {newElement}
                                    </span>
                                )
                            });

                            if (!line?.nodeObject?.children?.length) return;

                            const lineStyle = {
                                width: "100%",
                            };

                            return (
                                <div className={styles.latexLine} id={id + "-latex-line-" + lineIndex} style={lineStyle}>
                                    {lineContent}
                                </div>
                            );
                        })
                        :
                        <div className={styles.latexLine} id={id + "-latex-line-default"}>
                            {cursor}
                        </div>
                }
            </div>
            <div id={id + "-hidden-div"} style={{ position: "absolute", transform: "scale(0)", visibility: "hidden" }}>
                {
                    canvas?.nodeObject?.children.map((child, index) => {
                        return child.nodeObject.fullParent;
                    })
                }
            </div>
        </div >
    )
}

function splitReactElementToSpans(reactElement, canvasIndex, currentNode, cursor, id, selectionTails = null, selection = null) {
    // Extract children from the react element (assuming it's text)
    const text = reactElement.props.children;

    // Create an array of spans for each character
    if (!text) {
        let shouldBeSelect = selectionTails ? currentNode.startIndex >= selectionTails[0].realIndex && currentNode.startIndex + 1 <= selectionTails[1].realIndex : false;

        return (
            <span className={`${shouldBeSelect ? styles.selectedItem : ""}`}>
                {cursor}
            </span>
        )
    };


    const charSpans = Array.from(text).map((char, index) => {
        let shouldBeSelect = selectionTails ? currentNode.startIndex + index >= selectionTails[0].realIndex && currentNode.startIndex + index + 1 <= selectionTails[1].realIndex : false;

        const newChar = React.cloneElement(reactElement, {
            // Spread existing props and then override or add additional props
            ...reactElement.props,
            className: `${reactElement.props.className} ${shouldBeSelect ? styles.selectedItem : ''}`, // Add a new class
            ref: reactElement.ref,
            nodeCount: "replaced",
            // Any other props you want to modify or add can go here
        }, char);

        return (
            <>
                {newChar}
                {
                    index === canvasIndex - currentNode.startIndex - 1 &&
                    cursor
                }
            </>
        )
    });

    // Wrap these spans in a parent span
    const nodeCount = `${id}-${currentNode.startIndex}-${currentNode.endIndex}`
    return <span id="current-node" nodeCount={nodeCount}>{charSpans}</span>;
};

function flattenTreeToLeaves(node, basePath = [], surfaceOffsetIndex = 0) {
    let leaves = [];

    function traverse(node, path, surface = false) {
        if (node) {
            if (!node.nodeObject.children || node.nodeObject.children.length === 0) {
                // Add the 'path' key inside the 'nodeObject'
                node.nodeObject.path = path;
                leaves.push(node);
            } else {
                // Recursively traverse each child with an updated path
                node.nodeObject.children.forEach((child, index) => {
                    traverse(child, [...path, surface ? index + surfaceOffsetIndex : index]);
                });
            }
        }
    }

    traverse(node, basePath, true); // Start traversing from the root node with an empty path
    return leaves;
};

function flattenTreeToDeepestLeaves(node, basePath = [], surfaceOffsetIndex = 0) {
    let leaves = [];

    function traverse(node, path, surface = false) {
        if (node) {
            if (!node.nodeObject.children || node.nodeObject.children.length === 0) {
                // Add the 'path' key inside the 'nodeObject'
                node.nodeObject.path = path;
                leaves.push(node);
            } else {
                // Recursively traverse each child with an updated path

                if (!node?.nodeObject?.containerNode) {
                    node.nodeObject.children.forEach((child, index) => {
                        traverse(child, [...path, surface ? index + surfaceOffsetIndex : index]);
                    });
                } else {
                    traverse(node.nodeObject.children[node.nodeObject.children.length - 1], [...path, node.nodeObject.children.length - 1]);
                };
            }
        }
    }

    traverse(node, basePath, node, true); // Start traversing from the root node with an empty path
    return leaves;
};

function flattenTreeToShallowestLeaves(node, basePath = [], surfaceOffsetIndex = 0) {
    let leaves = [];

    function traverse(node, path, surface = false) {
        if (node) {
            if (!node.nodeObject.children || node.nodeObject.children.length === 0) {
                // Add the 'path' key inside the 'nodeObject'
                node.nodeObject.path = path;
                leaves.push(node);
            } else {
                // Recursively traverse each child with an updated path

                if (!node?.nodeObject?.containerNode) {
                    node.nodeObject.children.forEach((child, index) => {
                        traverse(child, [...path, surface ? index + surfaceOffsetIndex : index]);
                    });
                } else {
                    traverse(node.nodeObject.children[0], [...path, 0]);
                };
            }
        }
    }

    traverse(node, basePath, true); // Start traversing from the root node with an empty path
    return leaves;
};

function findIndex(array, index) {
    for (let i = array.length - 1; i >= 0; i--) {
        if (index >= array[i].nodeObject.startIndex) {
            return i;
        }
    }
    return 0; // or appropriate default
};

function findIndexReverse(array, index) {
    for (let i = 0; i < array.length; i++) {
        if (index <= array[i].nodeObject.endIndex) {
            return i;
        }
    }
    return 0; // or appropriate default
};

function getNodeAtPath(nodeObject, nodePath) {
    let currentNode = nodeObject.nodeObject;

    for (let i = 0; i < nodePath.length; i++) {
        if (!currentNode || !currentNode.children) {
            // If the current node is null or doesn't have children, return null
            return null;
        };
        currentNode = currentNode?.children[nodePath[i]]?.nodeObject || null;
    }

    return currentNode;
};

function arraysAreEqual(arr1, arr2) {
    const len = arr1.length;
    if (len !== arr2.length) return false;
    for (let i = 0; i < len; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
}

function arraysAreEqualS(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
}

function startsWithArray(arr, startArr) {
    return startArr.every((val, index) => val === arr[index])
};

function refineAndDeduplicatePaths(paths, canvas, shortestPathLength) {
    let uniquePaths = new Set(); // Use a Set to store unique string representations of paths
    let results = []; // Array to store the final unique paths

    // Identifying unique paths up to the point of divergence
    paths.forEach(path => {
        let grandParent = path.nodeObject.path[0];
        let newPath = path.nodeObject.path; // Initialize newPath with the original path

        for (let i = 1; i < path.nodeObject.path.length; i += 1) { // Iterate over each path to find divergence
            let divergenceFound = false;

            for (let j = 0; j < paths.length; j++) {
                if (i < paths[j].nodeObject.path.length && path.nodeObject.path[i] !== paths[j].nodeObject.path[i] && grandParent === paths[j].nodeObject.path[0]) {
                    divergenceFound = true;
                    newPath = path.nodeObject.path.slice(0, i % 2 === 0 ? i + 1 : i); // Trim at the point of divergence
                    break;
                }
            }

            if (divergenceFound) break; // Stop if divergence is found
        }

        if (newPath.length > shortestPathLength) {
            newPath = newPath.slice(0, shortestPathLength);
        };

        // Add the string representation of the newPath to the Set to ensure uniqueness

        if (!uniquePaths.has(JSON.stringify(newPath))) {
            uniquePaths.add(JSON.stringify(newPath));
        };
    });

    // Convert the Set back into an array of paths
    uniquePaths.forEach(pathStr => results.push({ nodeObject: getNodeAtPath(canvas, JSON.parse(pathStr)) }));

    return results;
};

function CopyMe(TextToCopy) {
    const currentFocus = document.activeElement;

    var TempText = document.createElement("input");
    TempText.value = TextToCopy;
    document.body.appendChild(TempText);
    TempText.select();

    document.execCommand("copy");
    document.body.removeChild(TempText);

    currentFocus.focus({ preventScroll: true });
};