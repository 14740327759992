import styles from '../styles/AlisaKan.module.css';
import { useContext } from 'react';
import DataContext from '../context/DataContext';
import ThemeToggle from "../components/ThemeToggle";
import { useNavigate } from "react-router-dom";

export default function AlisaKan() {
    const navigate = useNavigate();
    const { overview, theme } = useContext(DataContext);

    return (
        <>
            <div className={styles[theme === "dark" ? "noisyDark" : "noisyLight"]}></div>
            <div className={styles.container}>
                <div className={styles.navbar}>
                    <ThemeToggle />
                </div>
                <div className={styles.overview}>
                    {overview.map((topic, i) => {
                        const { title, topics } = topic;

                        return (
                            <div className={styles.topicContainer}>
                                <h3 className={styles.topicTitle}>{i + 1} {title}</h3>
                                {topics.map((subTopic, j) => {
                                    const { title } = subTopic;

                                    function handleMemoriseClick() {
                                        // Navigate to the route corresponding to the subTopic
                                        navigate(`memorise/${i}/${j}`);
                                    };                                

                                    return (
                                        <div className={styles.subTopicContainer}>
                                            <div className={styles.subTopicTitleContainer}>
                                                <h3 className={styles.subTopicTitleNumber}>{i + 1}.{j + 1}</h3>
                                                <h3 className={styles.subTopicTitle}>{title}</h3>
                                            </div>
                                            <div className={styles.functionContainer}>
                                                {/* <button className={styles.functionButton}>Notes</button> */}
                                                <button onClick={handleMemoriseClick} className={styles.functionButton}>Memorise</button>
                                                {/* <button className={styles.functionButton}>Play</button> */}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}