import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import { useContext, useEffect, useState } from 'react';
import Training from './pages/Training';
import DataContext from './context/DataContext';
import UpdateCard from './components/UpdateCard';
import NavBar from './components/NavBar';
import Specifications from './pages/Specifications';
import Factory from './pages/Factory';
import Courses from './pages/Courses';
import Simulator from './pages/Simulator';
import Papers from './pages/Papers';
import Paper from "./pages/Paper";
import AlisaKan from "./pages/AlisaKan";
import Memorise from './pages/Memorise';

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { papers, trainingCentres, courses } = useContext(DataContext);

  useEffect(() => {
    if (localStorage.getItem("isAuthorized") !== null) {
      setIsAuthorized(true);
    };
  }, []);

  return (
    <div tabIndex="0" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", boxSizing: "border-box" }}>
      {/* <UpdateCard /> */}
      {/* <NavBar /> */}
      <div style={{ width: "100vw", height: "calc(100vh)", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
        <Routes>
          {/* <Route path='/' element={<Home />}></Route>
          <Route path='/simulator' element={<Simulator />} />
          <Route path='/factory' element={<Factory />} />
          <Route path='/papers' element={<Papers />} />
          <Route path='/courses' element={<Courses />} />
          <Route path='/training' element={<Training />}></Route>
          <Route path='/specifications' element={<Specifications />}></Route> */}
          <Route path='/alisakan' element={<AlisaKan />}></Route>
          {/* <Route path='*' element={<Home />}></Route> */}
          {/* {courses.map((course) => {
            return course.modules.map((module, i) => {
              return (
                <Route path={`/courses/${course.path}/${module.path}`} element={module.handler}></Route>
              )
            })
          })} */}
          {/* <Route path="/paper/:paperId" element={<Paper />} /> */}
          <Route path="alisakan/memorise/:topic/:subtopic" element={<Memorise />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;