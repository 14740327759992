import React from 'react';
import styles from "../../styles/courses/DivisionAlgorithm.module.css";
import StatefulLatexEditor from '../../components/Editor/StatefulLatexEditorV2';

function DivisionAlgorithm() {
    return (
        <div className={styles.pageContainer}>
            <div className={styles.moduleContainer}>
                <StatefulLatexEditor id={'0'} text={`
                If \\bold{a} and \\bold{b} are integers where \\bold{a} \\notequal 0
                \\newline{}then \\bold{b} is divisible by \\bold{a} \\bold{if} there exists an integer \\bold{k}
                \\newline{}such that \\bold{b} = \\bold{k}\\bold{\\cdot}\\bold{a}
                \\newline{}
                `} lineWidth={"100%"} editable={false} />

                <StatefulLatexEditor id={'1'} text={`
                \\bold{a} divides \\bold{b} is denoted by \\bold{a} \\bold{\\divides} \\bold{b}
                \\newline{}\\bold{a} does not divide \\bold{b} is denoted by \\bold{a} \\bold{\\notdivides} \\bold{b}
                \\newline{}
                `} lineWidth={"100%"} editable={false} />

                <StatefulLatexEditor id={'2'} text={`
                 \\bold{a} \\divides \\bold{b}
                 \\newline{}\\bold{a} is called the \\bold{divisor}
                 \\newline{}\\bold{b} is called the \\bold{dividend}
                `} lineWidth={"100%"} editable={false} />
            </div>
        </div>
    );
};

export default DivisionAlgorithm;