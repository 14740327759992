import React, { createContext } from 'react';

const LatexContext = createContext();

export const LatexProvider = ({ children, styles }) => {
    const globalStyles = {
        // default styles
        fontSize: '1rem',
        fontFamily: 'Arial',
        ...styles,
    };

    return (
        <LatexContext.Provider value={{ globalStyles }}>
            {children}
        </LatexContext.Provider>
    );
};

export default LatexContext;